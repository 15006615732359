import Vue from 'vue'
import store from '@/store/index' //para acceder a state 
import { handlerErrors,  currentDate, formatDate, consoleMsgFinally, formatListProducts,formatListTransfer, formatListPackage, formatListEvents } from '@/helpers/helpers'
import { fetchCategories, fetchCurrencies } from '@/modules/start/store/actions'



export async function getInitialContent( context ){             
     
    context.commit('start/setLoadingCategories', true, { root: true }) //loader para categorías      
    const content = await Promise.all([        
        fetchCategories(), fetchCurrencies(), fetchPlaces(), 
    ])    
    const [ categories, currencies,places ] = content  

    context.commit('start/setCategories', categories, { root: true }) //muto state categories en start   
    context.commit('start/setLoadingCategories', false, { root: true }) //quito loader para categorías     
    context.commit('start/setCurrencies', currencies, { root: true }) //muto state currencies en start 

    context.commit('setPlaces', places ) //muto state products en products 
    
    return content
}

export async function getCategoriesAndCurrencies( context ){             
     
    context.commit('start/setLoadingCategories', true, { root: true }) //loader para categorías      
    const content = await Promise.all([        
        fetchCategories(), fetchCurrencies(),  //make 2 requests
    ])    
    const [ categories, currencies ] = content  
    context.commit('start/setCategories', categories, { root: true }) //muto state categories en start   
    context.commit('start/setCurrencies', currencies, { root: true }) //muto state currencies en start 
    context.commit('start/setLoadingCategories', false, { root: true }) //quito loader para categorías     
  
    return content
}

//para peticiones asyncronas para obtener lista de productos best seller
export async function fetchProducts(context) {
    const idseccanal =  store.state.auth.user?.sectionCanal?.id
    try {
        context.commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/products/`,
            params: { bs: 1,
                idseccanal
             }
        })
        const payload = response && response.data
        return formatListProducts( payload, 'BestSeller-01' )
    } catch (error) {
        console.log( error )
    } finally {
        context.commit('products/setLoadingProducts', false, { root: true })
        consoleMsgFinally('products/fetchProductsBestSeller', 'end')
    }
}

export async function fetchProductsRate(context,payloadParams) {
    const idseccanal =  store.state.auth.user?.sectionCanal?.id
    const currency = store.state?.shop?.currency?.code

    const {  dateRange, adults, children,idCat, idsub } = payloadParams
    try {
        context.commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/productsRates/`,
            params: {
                idseccanal,
                dateRange,
                adults,
                children,
                idCat,
                idsub,
                currency
            }
        })
        const payload = response && response.data
        return formatListProducts( payload, 'Rates-01' )
    } catch (error) {
        console.log( error )
        return []
    } finally {
        context.commit('products/setLoadingProducts', false, { root: true })
        consoleMsgFinally('products/fetchProductsBestSeller', 'end')
    }
}


export async function fetchTransfersRate(context,payloadParams) {
    const idseccanal =  store.state.auth.user?.sectionCanal?.id
    const currency = store.state?.shop?.currency?.code
  
    const {   pax, originPlaceId,destinationPlaceId, operationDateDeparture,operationDateArrival,selectedOption } = payloadParams
    try {
        context.commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/productsTransferRates/`,
            params: { 
                idseccanal,
                operationDateDeparture,
                operationDateArrival,
                pax,
                originPlaceId,
                destinationPlaceId,
                selectedOption,
                currency
            }
        })
        const payload = response && response.data
        return formatListTransfer( payload, 'Rates-01' )
    } catch (error) {
        console.log( error )
    } finally {
        context.commit('products/setLoadingProducts', false, { root: true })
        consoleMsgFinally('products/fetchProductsBestSeller', 'end')
    }
}
//peticiones para obtener lista de productos por categorias
export async function fetchProductsBySubCategory(context, subCategory) {
    const { idSubCategory, value } = subCategory
    const idseccanal =  store.state.auth.user?.sectionCanal?.id       
    try {   
        context.commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/products/`,
            params: { idsub: idSubCategory, idseccanal }
        })
        const payload = response && response.data   
           
        return formatListProducts( payload, value ) 

    } catch (error) {
        console.log(error)
    } finally {     
        context.commit('products/setLoadingProducts', false, { root: true })           
        consoleMsgFinally('fetchProductsBySubCategory', 'end')
    }
}

//peticiones para obtener lista de productos por categorias
export async function fetchProductsByCategories({ commit }, category) {
    const { value } = category 
    const idseccanal =  store.state.auth.user?.sectionCanal?.id       
    try {
        commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/products/`,
            params: {
                idcat: value,
                idseccanal
            }
        })
        const payload = response && response.data
        return formatListProducts( payload, value )        
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('products/setErrorByCategoriesProducts', errorMsg, { root: true })
    } finally {
        commit('products/setLoadingProducts', false, { root: true })
        consoleMsgFinally('products/fetchProductsByCategories', 'end')

    }
}


export async function fetchPlaces() {

    let  placeTypeId = [{'placeTypeId': '6'}, {'placeTypeId': '7'}, {'placeTypeId': '4'}]
    try {
        
        const response = await Vue.axios({
            url: '/place/getPlaces/',
            params: {
                placeTypeId
            },
            
        });
        const payload = response && response.data;

        return payload.places;
    } catch (error) {
      
       
    } finally {
    
        consoleMsgFinally('products/fetchProductsByCategories', 'end');
    }
}



//peticiones para obtener lista de paquetes
export async function fetchPackages({ commit }) {
    const currency = store.state?.shop?.currency?.code
    const idseccanal =  store.state.auth.user?.sectionCanal?.id
    try {
        commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/packages/`,
            params: {                
                idseccanal,
                currency
            }
        })
        const payload = response && response.data
        //le agrego el tipo (productos, eventos, paquetes)
        return formatListPackage( payload, 'f20a5f0e-7d3e-11ec-90d6-0242ac120003' )
        
    } catch (error) {
        console.log( error )
        return []
    } finally {
        commit('products/setLoadingProducts', false, { root: true })
        consoleMsgFinally('products/fetchPackages', 'end')
    }
}

//peticiones para obtener lista de eventos
export async function fetchEvents({ commit }) {
    const currency = store.state?.shop?.currency?.code
    const idseccanal =  store.state.auth.user?.sectionCanal?.id
    try {
        commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/events/`,
            params: {
                idseccanal,
                currency
            }
        })
        const payload = response && response.data
        const todayDate = currentDate()
        const fecha = new Date(todayDate)
        //se filtra los eventos que sean iguales o mayores a la fecha actual
        const result = payload.filter(item => new Date(item.endDate) >= fecha)

        return formatListEvents( result, 'e634becc-7d3e-11ec-90d6-0242ac120003')

    } catch (error) {
        console.log(error)
        return []
    } finally {
        commit('products/setLoadingProducts', false, { root: true })
        consoleMsgFinally('products/fetchEvents', 'end')
    }
}

export async function fetchProductDetail({ commit }, payload) {
    const currency = store.state?.shop?.currency?.code

    const { idProducto, endPoint } = payload
    const idseccanal =  store.state.auth.user?.sectionCanal?.id

    try {
        commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/${endPoint}/`,
            params: { id: idProducto, idseccanal, currency }
        })
        const respuesta = response && response.data
        let product = null
        if (endPoint == 'packages') {
            product = formatListPackage( respuesta, 'f20a5f0e-7d3e-11ec-90d6-0242ac120003')
        }

        else if (endPoint == 'products') {
            const categoryName = respuesta[0].category.name || ''
            product = formatListProducts( respuesta, categoryName )
        }

        else if (endPoint == 'events') {
            product = formatListEvents( respuesta, 'e634becc-7d3e-11ec-90d6-0242ac120003')
        }
        commit('products/setDetailProduct', product[0], { root: true })
        return product[0]
    } catch (error) {
        console.log(error)
        return null
    } finally {
        commit('products/setLoadingProducts', false, { root: true })
        consoleMsgFinally('products/fetchProductDetail', 'end')
    }
}

export async function fetchExperienceDetail({ commit }, payloadParams) {
    const currency = store.state?.shop?.currency?.code
    const idseccanal =  store.state.auth.user?.sectionCanal?.id
    const {  id,dateRange,adults, children ,idCat} = payloadParams
    try {
        commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/productsRates/`,
            params: { bs: null,
                id:id,
                idseccanal,
                dateRange,
                adults,
                children,
                idCat,
                currency
             }
        })
        const respuesta = response && response.data
        let product = null

        const categoryName = respuesta[0].category.name || ''
        product = formatListProducts( respuesta, categoryName )

        commit('products/setDetailProduct', product[0], { root: true })
        return product[0]
    } catch (error) {
        console.log(error)
        return false
    } finally {
        commit('products/setLoadingProducts', false, { root: true })
        consoleMsgFinally('products/fetchProductDetail', 'end')
    }
}
export async function fetchproductswebsites({ commit }, payloadInfo) {

    const { idProducto, endPoint, language } = payloadInfo
    const idseccanal =  store.state.auth.user?.sectionCanal?.id
    try {
        commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/${endPoint}/`,
            params: {
                id: idProducto,
                language,
                idseccanal
            }
        })
        const payload = response && response.data
        return payload

    } catch (error) {
        console.log(error)
        return false
    } finally {
        commit('products/setLoadingProducts', false, { root: true })
        consoleMsgFinally('products/fetchproductswebsites', 'end')
    }
}