import Vue from 'vue'

import { showAlertMessage, consoleMsgFinally, toJson } from '../../../../helpers/helpers'
import { formatBookings, formatBookingsPriceMatch, formatRoomPromoCode, makePayloadRoomRate } from '../../../../helpers/shopHelper'
import { fetchHotels, fetchCurrencies, fetchPromoCodes } from '../../../start/store/actions'
import store from '../../../../store' //para acceder a state


export async function getInitialContentBooking( context ){
    const payloadHotel = { onlyDingusCode: true, all: false, showInWeb: false }
    const content = await Promise.all([
        fetchHotels(payloadHotel), fetchCurrencies(), fetchPromoCodes(), //make 4 requests
    ])
    const [ hotels, currencies, promoCodes] = content

    context.commit('start/setHotels', hotels, { root: true }) //muto state hotels en start
    context.commit('start/setCurrencies', currencies, { root: true }) //muto state currencies en start
    context.commit('start/setPromoCodes', promoCodes, { root: true }) //muto state promoCodes en start
    return content
}

export async function fetchRooms({ commit }, datos) {
    const currency = store.state?.shop?.currency?.code
    datos.currency = currency
    const { fromTab } = datos
    try {
        const response = await Vue.axios({
            method:'POST',
            url: `/bookAvailable/`,
            data: datos
        })
        const respuesta = response && response.data
        const { status, isPromo, data } = respuesta
        if( status ){
            if (data?.length){
                let payload = JSON.parse(JSON.stringify( data ))
                return formatBookings( payload, isPromo, fromTab)
            } else {
                if (data?.message) showAlertMessage('Busqueda terminada', 'BellIcon', `😔 No se pudo completar la operacion: ${data.message}`, 'warning', 9000, 'bottom-right')
                return []
            }

        } else {
            showAlertMessage('Busqueda terminada', 'BellIcon', `😔 No se pudo completar la operacion, contactar a soporte ` , 'warning', 9000, 'bottom-right')
            return []
        }

    } catch (error) {
        console.log(error);
        if( error.response ){
            if (error.response?.status) {
                showAlertMessage('Busqueda terminada',
                'BellIcon', `Error ${error.response.status} : ${error.response.statusText} | ${error.message}`
                , 'danger', 9000, 'bottom-right')
            }
        }
        return []
    } finally {
        consoleMsgFinally('bookings/fetchRoom','La petición fetchRoom ha terminado')
    }
}


export async function fetchRoomPriceMatch(context, datos) {
    const currency = store.state?.shop?.currency?.code
    datos.currency = currency
    const { keyRoom } = datos
    const previousRooms = toJson(store.state.bookings.rooms)
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/bookAvailable/`,
            data: datos
        })
        const respuesta = response && response.data
        const { status, isPromo, message } = respuesta
        if( status ){
            let payload = JSON.parse(JSON.stringify( respuesta.data ))
            if(message){
                showAlertMessage('No se encontraron resultados', 'InfoIcon', `😪😪 ${message}`, 'danger', 4000, 'bottom-right')
            }
            if( payload.length > 0 ){
                const newRoom = formatRoomPromoCode( payload[0], datos, message, isPromo )
                return makePayloadRoomRate( { keyRoom, newRoom, previousRooms } )
            } else {
                return  []
            }
        } else {
            return []
        }
    } catch (error) {
        console.log(error)
        if( error.response ){
            if (error.response?.status) {
                showAlertMessage('Busqueda terminada',
                'BellIcon', `Error ${error.response.status} : ${error.response.statusText} | ${error.message}`
                , 'danger', 9000, 'bottom-right')
            }
        }
        return []
    } finally {
        consoleMsgFinally('mySales/fetchRoomPriceMatch', 'La petición ha terminado')
    }
}

export async function validateUser(context, datos) {
    const { username, canalVenta } = datos
    try {
        const response = await Vue.axios({
            url: `/authlogin/`,
            params: { username, canalVenta }
        })
        const respuesta = response && response.data
        const { status } = respuesta
        if (status) {
            const { id } = respuesta
            return { idUser: id, isInvalid: false }
        } else {
            return { idUser: null, isInvalid: true }
        }
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('bookings/validateUser', 'La petición validateUser ha finalizado')
    }
}

export async function authorizeRateUser(context, datos) {
    const { username, file } = datos
    // console.log(datos, file )
    try {
        const formData = new FormData()
        file.forEach(files => {
            formData.append('file', files)
        })
        formData.append('data', JSON.stringify(datos))
        // console.log(formData)
        const response = await Vue.axios({
            method: 'POST',
            url: `/authlogin/`,
            data: formData
        })
        const respuesta = response && response.data
        const { status, urlAuthorization } = respuesta

        if (status) return urlAuthorization
        else return { url: null }

    } catch (error) {
        if (error.response) {
            if (error.response.statusText == 'Unauthorized') {
                showAlertMessage('Código incorrecto', 'InfoIcon', `😪😪 El código para su usuario ${username} es incorrecto`, 'danger', 4000, 'bottom-right')
                return false
            }
            if (error.response.statusText == 'Bad Request') {
                showAlertMessage('Bad Request', 'InfoIcon', `😪😪 Petición invalida`, 'danger', 4000, 'bottom-right')
                return false
            }
        }
    } finally {
        consoleMsgFinally('mySales/authorizeRateUser', 'La petición authorizeRateUser ha finalizado')
    }
}


export async function authorizeRate({commit},datos) {
    const { username, file, key } = datos
    // console.log(datos, file )
    try {
        commit('bookings/setLoadingProcess', true, { root: true }) //init loader
        const formData = new FormData()
        formData.append('file', file)
        formData.append('data', JSON.stringify(datos))
        // console.log(formData)
        const response = await Vue.axios({
            method:'POST',
            url: `/authlogin/`,
            data: formData
        })
        const respuesta = response && response.data
        const { status, urlAuthorization } = respuesta
        if( status ){
            showAlertMessage(
                'Autorización realizada', 'InfoIcon',
                '🎉🎉La autorización ha sido exitoso',
                'success', 4000, 'bottom-right'
            )
            const dataUrl = {key, url: urlAuthorization}
            commit('bookings/setUserAuthorizationUrl', dataUrl, { root: true })
        }
    } catch (error) {
        if(error.response){
            if( error.response.statusText == 'Unauthorized' ){
                showAlertMessage( 'Código incorrecto', 'InfoIcon', `😪😪 El código para su usuario ${username} es incorrecto`, 'danger', 4000, 'bottom-right')
                commit('bookings/unsetDataUserAuthorization', datos, { root: true })
            }
            if( error.response.statusText == 'Bad Request' ){
                showAlertMessage( 'Bad Request', 'InfoIcon', `😪😪 Petición invalida`, 'danger', 4000, 'bottom-right')
                commit('bookings/unsetDataUserAuthorization', datos, { root: true })
            }
        }
    } finally {
        commit('bookings/setLoadingProcess', false, { root: true }) //init loader
        consoleMsgFinally('bookings/authorizeRate','La petición para autorizar con código ha finalizado')
    }
}

