import Vue from 'vue'

import {  showAlertMessage, consoleMsgFinally, handlerErrors, makeParamsFilterDataOperations, makeParamsFilterUpdateDataOperation, responseMsgUpdate } from '../../../helpers/helpers'
import {  fetchHotels, fetchDestinations, fetchAirlines, } from '../../start/store/actions'

export async function getInitialContentOperations( context ){
    const payloadHotels = { onlyDingusCode: false, all: true, showInWeb: false }
    const content = await Promise.all([
        fetchCategories(), fetchHotels(payloadHotels), fetchDestinations(), fetchAirlines(),, //make 3 requests
    ])
    const [ categories, hotels, destinations, airlines ] = content

    context.commit('setCategories', categories) //muto state hotels en start
    context.commit('start/setHotels', hotels, { root: true }) //muto state start/setUsersChannel
    context.commit('start/setDestinations', destinations, { root: true }) //muto state destiantions in start
    context.commit('start/setAirlines', airlines, { root: true }) //muto state airlines en start

    return content
}

export async function getOrderDetailByOrder(context, payload) {
    const { idOrder }   = payload
    try {
        const response = await Vue.axios({
                url: '/ordersdetail/',
                params: {
                    ido: idOrder
                },
            })
            // console.log(response)
        const respuesta = response && response.data
        return respuesta[0]
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('operations/getOrderDetailByOrder', 'La petición getOrderDetailByOrder finalizó')

    }
}

//peticiones para obtener lista de ordenes por usuario
export async function fetchOrdersOperation(context, payload) {
    //uso el contexto para acceder a los datos del usuario conectado (modulo auth)
    // const  {idUser} = context.rootState.auth.user
    const parametros = makeParamsFilterDataOperations(payload)

    try {
        context.commit('operation/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            url: `/ordersoperation/?`,
            params: {
                ...parametros
            }
        })
        const orders = response && response.data
        context.commit('operation/setOrdersOperation', orders, { root: true })

    } catch (error) {
        console.log( error )
    } finally {
        context.commit('operation/setLoadingProcess', false, { root: true })
        consoleMsgFinally('fetchOrdersOperation','end')
    }
}



export async function updateOrderOperation({ commit }, payload) {

    const { tipo } = payload
    try {
        if (tipo != 'autorizacion') commit('operation/setLoadingProcess', true, { root: true })
        const data = makeParamsFilterUpdateDataOperation(payload)

        const response = await Vue.axios({
            method: 'POST',
            url: `/ordersoperation/`,
            data: {
                ...data
            }
        })

        const respuesta = response && response.data
        const { status } = respuesta
        commit('operation/setLoadingProcess', false, { root: true })
        if( status ) showAlertMessage('Success', 'BellIcon', `¡🎉🎉${ responseMsgUpdate(tipo)}!` , 'success', 4000,'bottom-right')
        else showAlertMessage('Error', 'BellIcon', '😪😪 Ocurrió un error con la actualización.', 'danger', 4000,'bottom-right')
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('operation/setErrorUpdateDataOperation', errorMsg, { root: true })
    } finally {
        commit('operation/setLoadingProcess', false, { root: true })
        consoleMsgFinally('operation/updateOrderOperation','La petición para actualizar el estado del order se ha terminado')

    }
}


export async function updateItineraryDetail({ commit }, payload) {

    try {
        commit('operation/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            method: 'POST',
            url: '/ordersdetailitinerary/',
            data: payload
        })
        const respuesta = response && response.data
        const { status } = respuesta
        if( status ) showAlertMessage('Success', 'BellIcon', '🎉🎉Los datos del itinerario han sido actualizados.', 'success', 4000,'bottom-right')
        else showAlertMessage('Error', 'BellIcon', '😪😪 Ocurrió un error con la actualización.','danger', 4000,'bottom-right')
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('operation/setErrorUpdateItineraryOrdersOperation', errorMsg, { root: true })
    } finally {
        commit('operation/setLoadingProcess', false, { root: true })
        consoleMsgFinally('operation/updateItineraryDetail','La petición para la actualización del itinerario finalizó')
    }
}

export async function updateItinerary({ commit }, payload) {

    try {
        commit('operation/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            method: 'POST',
            url: '/ordertransfersupdate/',
            data: payload
        })
        const respuesta = response && response.data
        commit('operation/updateInfoValid', respuesta.status, { root: true })
        const { status } = respuesta
        if( status ) showAlertMessage('Success', 'BellIcon', '🎉🎉Datos de itinerario actualizados.', 'success', 4000,'bottom-right')
        else showAlertMessage('Error', 'BellIcon', '😪😪 Ocurrió un error con la actualización.', 'danger', 4000,'bottom-right')
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('operation/setErrorUpdateItinerary', errorMsg, { root: true })
    } finally {
        commit('operation/setLoadingProcess', false, { root: true })
        consoleMsgFinally('operation/updateItinerary','La petición para la actualización del itinerario finalizó')
    }
}

export async function fetchSuppliers({ commit }, idOrdendetail) {

    try {
        //commit('operation/setLoadingProcess', true, { root: true }) 
        const response = await Vue.axios({
            url: `/productsupplier/`,
            params: {
                idOrdendetail
            }
        })
        const payload = response && response.data

        payload.forEach((supplier) => {
            supplier.value = supplier.id,
                supplier.text = supplier.name
            delete supplier.id
            delete supplier.name
        })
        payload.unshift({ value: null, text: 'Please select an option' })
        commit('operation/setSuppliers', payload, { root: true })

    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('operation/setErrorFetchSuppliers', errorMsg, { root: true })
    } finally {
        //commit('operation/setLoadingProcess', false, { root: true })
        consoleMsgFinally('operation/fetchSuppliers','La petición para obtener proveedores para el orden detail se ha terminado')

    }
}

export async function fetchOperatorsBySupplier({ commit }, idSupplier) {

    try {
        //commit('operation/setLoadingProcess', true, { root: true }) 
        const response = await Vue.axios({
            url: `/catalogs/transferoperator/`,
            params: {
                idSupplier
            }
        })
        const payload = response && response.data

        payload.forEach((operator) => {
            operator.value = operator.id,
                operator.text = operator.name
            delete operator.id
            delete operator.name
        })
        payload.unshift({ value: null, text: 'Please select an option' })
        commit('operation/setOperators', payload, { root: true })

    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('operation/setErrorFetchOperatorsBySupplier', errorMsg, { root: true })
    } finally {
        //commit('operation/setLoadingProcess', false, { root: true }) 
        console.log('La petición para obtener los operadores del proveedor se ha terminado')
        consoleMsgFinally('operation/fetchOperatorsBySupplier','La petición para obtener los operadores del proveedor se ha terminado')

    }
}

export async function updateSupplierOperator({ commit }, payload) {

    try {
        commit('operation/setLoadingProcess', true, { root: true }) 
        const response = await Vue.axios({
            method: 'POST',
            url: '/ordersupplier/',
            data: payload
        })
        const respuesta = response && response.data
        const { status } = respuesta
        if( status ) showAlertMessage('Success', 'BellIcon', '🎉🎉Se ha guardado el proveedor y operador.', 'success', 4000, 'bottom-right')
        else showAlertMessage('Error', 'BellIcon','😪😪 Ocurrió un error con la actualización.', 'danger', 4000,'bottom-right')
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('operation/setErrorUpdateSupplierOperatorstate', errorMsg, { root: true })
    } finally {
        commit('operation/setLoadingProcess', false, { root: true })
        consoleMsgFinally('operation/updateSupplierOperator','La petición para la actualización de proveedor y operador finalizó')
    }
}

export async function confirmDetailOrder({ commit }, payload) {

    try {
        commit('operation/setLoadingProcess', true, { root: true }) 
        const response = await Vue.axios({
            method: 'POST',
            url: '/ordersconfirm/',
            data: payload
        })
        const respuesta = response && response.data
        const { status } = respuesta
        if( status ) showAlertMessage('Success', 'BellIcon', '🎉🎉Se ha confirmado exitosamente.', 'success', 4000, 'bottom-right')
        else showAlertMessage('Error', 'BellIcon','😪😪 Ocurrió un error con la confirmación.', 'danger', 4000, 'bottom-right')
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('operation/setErrorConfirmDetailOrder', errorMsg, { root: true })
    } finally {
        commit('operation/setLoadingProcess', false, { root: true })
        consoleMsgFinally('operation/confirmDetailOrder', 'La petición para obtener las ordenesOperation se ha terminado')
    }
}

export async function fetchCategories() {
    try {
        const response = await Vue.axios({
            url: '/catalogs/category/'
        })
        const payload = response && response.data
        
        payload.sort(function(a, b){
            if(a.name < b.name) { return -1}
            if(a.name > b.name) { return 1}
            return 0
        })

        return payload
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('operations/fetchCategories', 'La petición para obtener las categorías se ha terminado')
    }
}
