import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

// Modules
import appConfig from '@/modules/appConfig/store'
import auth from '@/modules/auth/store'
import start from '@/modules/start/store'
import shop from '@/modules/shop/store/shop'
import bookings from '@/modules/shop/store/bookings'
import products from '@/modules/shop/store/products'
import mySales from '@/modules/mySales/store'
import operation from '@/modules/operations/store'
import faq from '@/modules/faq/store'

import fivesClubCatalogs from '@/modules/fivesClub/store/catalogs'
import fivesClubContracts from '@/modules/fivesClub/store/contracts'
import fivesClubReports from '@/modules/fivesClub/store/reports'
import fivesClubProfit from '@/modules/fivesClub/store/profit'
import incidents from '@/modules/incidents/store'
import guestPager from '@/modules/guestPager/store'
import groups from '@/modules/groups/store'


Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth','shop','products','incidents','groups','fivesClubContracts','fivesClubCatalogs','fivesClubProfit','guestPager']
})

export default new Vuex.Store({
  modules: {
    appConfig,
    auth,
    shop,
    start,
    bookings,
    products,
    mySales,
    operation,
    faq,
    fivesClubCatalogs,
    fivesClubProfit,
    fivesClubContracts,
    fivesClubReports,
    incidents,
    groups,
    guestPager
  },
  plugins:[vuexLocal.plugin],

  strict: process.env.DEV,
})