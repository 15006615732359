export function filteredProducts( state ) {

    let products = state.products ? state.products : []
    

    if( products.length != 0 ){
        const orderBY = state.orderBy
        //con esto filtro los resultado por el name del producto, no es case sensitive
        if( state.filter.query.length > 1 ){
            products = products.filter(
                product => product.name.toLowerCase().includes ( state.filter.query.toLowerCase() ) ||
                           product.metatag?.toLowerCase().includes( state.filter.query.toLowerCase() )            
            )        
        }

        if( orderBY == "alfabetico" ){
            return products.sort((a,b) => a.name > b.name ? 1 : -1)
        } else if( orderBY == "price-asc" ){
            return products.sort((a, b) => parseFloat(a.detail[0].saleprice)-parseFloat(b.detail[0].saleprice))
        } else if( orderBY == "price-desc"){
            return products.sort((a, b) => parseFloat(b.detail[0].saleprice)-parseFloat(a.detail[0].saleprice))
        } else {
            return products        
        }
    
    }
    return products 
}

export function filteredTransfers( state ) {

    let transfers = state.transfers ? state.transfers : []
    if( transfers.length != 0 ){
        const orderBY = state.orderBy
        //con esto filtro los resultado por el name del producto, no es case sensitive
        if( state.filter.query.length > 1 ){
            transfers = transfers.filter(
                product => product.name.toLowerCase().includes ( state.filter.query.toLowerCase() ) ||
                           product.metatag?.toLowerCase().includes( state.filter.query.toLowerCase() )            
            )        
        }

        if( orderBY == "alfabetico" ){
            return transfers.sort((a,b) => a.name > b.name ? 1 : -1)
        } else if( orderBY == "price-asc" ){
            return transfers.sort((a, b) => parseFloat(a.detail[0].saleprice)-parseFloat(b.detail[0].saleprice))
        } else if( orderBY == "price-desc"){
            return transfers.sort((a, b) => parseFloat(b.detail[0].saleprice)-parseFloat(a.detail[0].saleprice))
        } else {
            return transfers        
        }
    
    }
    return transfers 
}

