// import checkIfUserHasPermission from './user-guard'
import store from '@/store'

export default [
    { // housing
        path: '/fives-club/housing',
        redirect: { name: 'housing-list' },
        name: 'housing',
        component: () => import(/*webpackChunkName: 'Housing'*/'@/modules/fivesClub/views/Housing'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_sub_menu_show_housing']
        },
        children: [
            // UserHome will be rendered inside User's <router-view>
            // when /fives-club/housing is matched
            { path: '', name: 'housing-list', component: () => import(/*webpackChunkName: 'ListHousing'*/'@/modules/fivesClub/views/ListHousing'), },
            { path: 'create', name: 'create-housing', component: () => import(/*webpackChunkName: 'CreateHousing'*/'@/modules/fivesClub/views/CreateHousing'), },
            { path: 'edit/:id', name: 'edit-housing', component: () => import(/*webpackChunkName: 'EditHousing'*/'@/modules/fivesClub/views/EditHousing'), },
        ]
    },
    { // condominiums
        path: '/fives-club/condominiums',
        redirect: { name: 'condominiums' },
        name: 'condominium',
        component: () => import(/*webpackChunkName: 'Condominiums'*/'@/modules/fivesClub/views/condominiums/Condominiums'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_sub_menu_show_housing']
        },
        children:[
            { path: '', name: 'condominiums', component: () => import(/*webpackChunkName: 'Condominiums'*/'@/modules/fivesClub/views/condominiums/condos/Condominiums'), },
            { path: 'create', name: 'create-condominium', component: () => import(/*webpackChunkName: 'CreateCondominium'*/'@/modules/fivesClub/views/condominiums/condos/CreateCondominium'), },
            { path: 'edit/:id', name: 'edit-condominium', component: () => import(/*webpackChunkName: 'EditCondominium'*/'@/modules/fivesClub/views/condominiums/condos/EditCondominium'), },
            { path: 'sub', name: 'sub-condominiums', component: () => import(/*webpackChunkName: 'SubCondominiums'*/'@/modules/fivesClub/views/condominiums/subcondos/SubCondominiums'), },
            { path: 'sub/create', name: 'create-sub-condominiums', component: () => import(/*webpackChunkName: 'CreateSubCondominiums'*/'@/modules/fivesClub/views/condominiums/subcondos/CreateSubCondominium'), },
            { path: 'sub/edit/:id', name: 'edit-sub-condominiums', component: () => import(/*webpackChunkName: 'EditSubCondominiums'*/'@/modules/fivesClub/views/condominiums/subcondos/EditSubCondominium'), },

        ]
    },

    { // rental-pool
        path: '/fives-club/rental-pool',
        redirect: { name: 'rental-pools' },
        name: 'rental-pool',
        component: () => import(/*webpackChunkName: 'RentalPool'*/'@/modules/fivesClub/views/rentalpool/RentalPool'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_sub_menu_show_housing']
        },
        children:[
            { path: '', name: 'rental-pools', component: () => import(/*webpackChunkName: 'RentalPoolList'*/'@/modules/fivesClub/views/rentalpool/RentalPoolList'), },
            { path: 'create', name: 'create-rental-pool', component: () => import(/*webpackChunkName: 'CreateRentalPool'*/'@/modules/fivesClub/views/rentalpool/CreateRentalPool'), },
            { path: 'edit/:id', name: 'edit-rental-pool', component: () => import(/*webpackChunkName: 'EditRentalPool'*/'@/modules/fivesClub/views/rentalpool/EditRentalPool'), },
        ]
    },
    { // benefits
        path: '/fives-club/benefits',
        redirect: { name: 'list-benefits' },
        name: 'benefits',
        component: () => import(/*webpackChunkName: "Benefits"*/'@/modules/fivesClub/views/benefits/Benefits'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_sub_menu_show_benefits']
        },
        children:[
            { path: '', name: 'list-benefits', component: () => import(/*webpackChunkName: 'BenefitsList'*/'@/modules/fivesClub/views/benefits/BenefitsList'), },
            { path: 'create', name: 'create-benefits', component: () => import(/*webpackChunkName: 'BenefitCreate'*/'@/modules/fivesClub/views/benefits/BenefitCreate'), },
            { path: 'edit/:idBenefit', name: 'edit-benefits', component: () => import(/*webpackChunkName: 'BenefitEdit'*/'@/modules/fivesClub/views/benefits/BenefitEdit'), },

            { path: 'type', name: 'type-benefits', component: () => import(/*webpackChunkName: 'TypeBenefits'*/'@/modules/fivesClub/views/benefits/TypeBenefits'), },

            { path: 'groups', name: 'groups-benefits', component: () => import(/*webpackChunkName: 'GroupTypeBenefits'*/'@/modules/fivesClub/views/benefits/GroupTypeBenefits'), },
        ]
    },
    { // blackouts
        path: '/fives-club/blackouts',
        name: 'blackouts',
        redirect: { name: 'blackouts-list' },
        component: () => import(/*webpackChunkName: "Blackouts"*/'@/modules/fivesClub/views/blackouts/Blackouts'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_sub_menu_show_blackouts']
        },
        children: [
            // UserHome will be rendered inside User's <router-view>
            // when /fives-club/housing is matched
            { path: '', name: 'blackouts-list', component: () => import(/*webpackChunkName: 'ListBlackouts'*/'@/modules/fivesClub/views/blackouts/BlackoutsList'), },
            {
                path: 'create',
                name: 'create-blackout',
                component: () => import(/*webpackChunkName: 'CreateBlackout'*/'@/modules/fivesClub/views/blackouts/BlackoutCreate'),
                meta: {
                    requiresAuth: true,
                    permissions: ['fivesclub_blackouts_show_blackouts_add_blackout']
                },
            },
            {
                path: 'edit/:id',
                name: 'edit-blackout',
                component: () => import(/*webpackChunkName: 'EditBlackout'*/'@/modules/fivesClub/views/blackouts/BlackoutEdit'),
                meta: {
                    requiresAuth: true,
                    permissions: ['fivesclub_blackouts_show_blackouts_list_action_button_update']
                },
            },
        ]
    },
    { // customers
        path: '/fives-club/customers',
        redirect: { name: 'customers-list' },
        name: 'customers',
        component: () => import(/*webpackChunkName: 'Owners'*/'@/modules/fivesClub/views/customers/Owners'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_sub_menu_show_owners']
        },
        children:[
            {
                path: '/fives-club/customers/list',
                name: 'customers-list',
                component: () => import(/*webpackChunkName: 'OwnersList'*/'@/modules/fivesClub/views/customers/OwnersList'),
                meta: {
                    permissions: ['fivesclub_sub_menu_show_owners']
                }
            },
            {
                path: '/fives-club/customers/create',
                name: 'customers-create',
                component: () => import(/*webpackChunkName: 'OwnersCreate'*/'@/modules/fivesClub/views/customers/OwnersCreate'),
                meta: {
                    permissions: ['fivesclub_owners_show_owners_create_buttons']
                }
            },
            {
                path: '/fives-club/customers/edit/:idOwner',
                name: 'customers-edit',
                component: () => import(/*webpackChunkName: 'OwnersEdit'*/'@/modules/fivesClub/views/customers/OwnersEdit'),
                meta: {
                    permissions: ['fivesclub_sub_menu_show_owners', 'fivesclub_owners_show_owners_edit_form_editable']
                }
            },
        ]
    },
    { // memberships
        path: '/fives-club/memberships',
        name: 'memberships',
        redirect: { name: 'memberships-list' },
        component: () => import(/*webpackChunkName: 'Memberships'*/'@/modules/fivesClub/views/memberships/Memberships'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_sub_menu_show_membership']
        },
        children:[
            {
                path: '',
                name: 'memberships-list',
                component: () => import(/*webpackChunkName: 'MembershipsList'*/'@/modules/fivesClub/views/memberships/MembershipsList'),
            },
            {
                path: 'create',
                name: 'create-membership',
                component: () => import(/*webpackChunkName: 'MembershipCreate'*/'@/modules/fivesClub/views/memberships/MembershipCreate'),
                meta: {
                    requiresAuth: true,
                    permissions: ['fivesclub_membership_show_membership_add']
                }
            },
            {
                path: 'edit/:idMembership',
                name: 'edit-membership',
                component: () => import(/*webpackChunkName: 'MembershipEdit'*/'@/modules/fivesClub/views/memberships/MembershipEdit'),
                meta: {
                    requiresAuth: true,
                    permissions: ['fivesclub_sub_menu_show_membership', 'fivesclub_membership_show_membership_list_action_button_edit_membership']
                },
            },
        ]
    },
    { // subscriptions
        path: '/fives-club/subscriptions',
        redirect: { name: 'exchanges' },
        name: 'subscriptions',
        component: () => import(/*webpackChunkName: 'Subscriptions'*/'@/modules/fivesClub/views/subscriptions/Subscriptions'),
        meta: {
            requiresAuth: true,
            // permissions: ['fivesclub_subscriptions_show_exchanges_list']
        },
        children:[
            {
                path: '/fives-club/subscriptions/exchanges',
                name: 'exchanges',
                component: () => import(/*webpackChunkName: 'Exchanges'*/'@/modules/fivesClub/views/subscriptions/Exchanges'),
                meta: {
                    // permissions: ['fivesclub_subscriptions_show_exchanges_list']
                }
            },
        ]
    },

    { // contracts
        path: '/fives-club/contracts',
        name: 'contracts',
        component: () => import(/*webpackChunkName: 'Contracts'*/'@/modules/fivesClub/views/Contracts'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_sub_menu_show_contracts']
        },
    },
    {
        path: '/fives-club/contracts/files/:idContract',
        name: 'docs-contract',
        component: () => import(/*webpackChunkName: 'DocumentsContract.vue'*/'@/modules/fivesClub/views/DocumentsContract.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_sub_menu_show_contracts']
        },
    },
    {
        path: '/fives-club/contracts/:idContract',
        name: 'edit-contract',
        component: () => import(/*webpackChunkName: 'EditContract.vue'*/'@/modules/fivesClub/views/EditContract.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_sub_menu_show_contracts']
        },
    },
    { // web-owners config
        path: '/fives-club/web-owners/config',
        redirect: { name: 'owners-newsletter' },
        name: 'owners-website-config',
        component: () => import(/*webpackChunkName: 'OwnersWebsite'*/'@/modules/fivesClub/views/ownersWebsite/OwnersWebsite'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_sub_menu_show_web_owners']
        },
        children:[
            // Newsletter
            { path: 'newsletter', name: 'owners-newsletter', component: () => import(/*webpackChunkName: 'OwnersNewsletter'*/'@/modules/fivesClub/views/ownersWebsite/config/OwnersNewsletter'), },
            { path: 'newsletter/create', name: 'create-newsletter', component: () => import(/*webpackChunkName: 'OwnersNewsletterCreate'*/'@/modules/fivesClub/views/ownersWebsite/config/OwnersNewsletterCreate'), },
            { path: 'newsletter/edit/:id', name: 'edit-newsletter', component: () => import(/*webpackChunkName: 'OwnersNewsletterEdit'*/'@/modules/fivesClub/views/ownersWebsite/config/OwnersNewsletterEdit'), },

            // Hotel performance
            { path: 'hotel-performance', name: 'hotel-performance', component: () => import(/*webpackChunkName: 'OwnersHotelPerformance'*/'@/modules/fivesClub/views/ownersWebsite/config/OwnersHotelPerformance'), },
            { path: 'hotel-performance/create', name: 'create-hotel-performance', component: () => import(/*webpackChunkName: 'OwnersHotelPerformanceCreate'*/'@/modules/fivesClub/views/ownersWebsite/config/OwnersHotelPerformanceCreate'), },
            { path: 'hotel-performance/edit/:id', name: 'edit-hotel-performance', component: () => import(/*webpackChunkName: 'OwnersHotelPerformanceEdit'*/'@/modules/fivesClub/views/ownersWebsite/config/OwnersHotelPerformanceEdit'), },

            // The Fives Vacations'
            { path: 'the-fives-vacations', name: 'fives-vacations', component: () => import(/*webpackChunkName: 'OwnersFivesVacations'*/'@/modules/fivesClub/views/ownersWebsite/config/OwnersFivesVacations'),
                meta: {
                    permissions: ['fivesclub_web_owners_show_the_fives_vacations_promos_list']
                }
            },
            { path: 'the-fives-vacations/create', name: 'create-fives-vacations', component: () => import(/*webpackChunkName: 'OwnersFivesVacationsCreate'*/'@/modules/fivesClub/views/ownersWebsite/config/OwnersFivesVacationsCreate'),
                meta: {
                    permissions: ['fivesclub_web_owners_show_the_fives_vacations_list_create_button']
                }
            },
            { path: 'the-fives-vacations/edit/:id', name: 'edit-fives-vacations', component: () => import(/*webpackChunkName: 'OwnersFivesVacationsEdit'*/'@/modules/fivesClub/views/ownersWebsite/config/OwnersFivesVacationsEdit'),
                meta: {
                    permissions: ['fivesclub_web_owners_show_the_fives_vacations_list_edit_button']
                }
            },

            // Staff contact
            { path: 'staff-contact', name: 'staff-contact', component: () => import(/*webpackChunkName: 'OwnersStaffContac'*/'@/modules/fivesClub/views/ownersWebsite/config/OwnersStaffContac'),
                meta: {
                    permissions: ['fivesclub_web_owners_show_staff_contacts_list']
                }
            },
            { path: 'staff-contact/create', name: 'create-staff-contact', component: () => import(/*webpackChunkName: 'OwnersStaffContacCreate'*/'@/modules/fivesClub/views/ownersWebsite/config/OwnersStaffContacCreate'),
                meta: {
                    permissions: ['fivesclub_web_owners_show_staff_contacts_add_button']
                }
            },
            { path: 'staff-contact/edit/:id', name: 'edit-staff-contact', component: () => import(/*webpackChunkName: 'OwnersStaffContacEdit'*/'@/modules/fivesClub/views/ownersWebsite/config/OwnersStaffContacEdit'),
                meta: {
                    permissions: ['fivesclub_web_owners_show_staff_contacts_edit_button']
                }
            },

            // General documents
            { path: 'general-documents', name: 'general-documents', component: () => import(/*webpackChunkName: 'OwnersDocs'*/'@/modules/fivesClub/views/ownersWebsite/config/OwnersDocs'),
                meta: {
                    permissions: ['fivesclub_web_owners_show_owners_general_documents_list']
                }
            },
            { path: 'general-documents/create', name: 'create-general-document', component: () => import(/*webpackChunkName: 'OwnersDocsCreate'*/'@/modules/fivesClub/views/ownersWebsite/config/OwnersDocsCreate'),
                meta: {
                    permissions: ['fivesclub_web_owners_show_owners_general_documents_add_button']
                }
            },
            { path: 'general-documents/edit/:id', name: 'edit-general-document', component: () => import(/*webpackChunkName: 'OwnersDocsEdit'*/'@/modules/fivesClub/views/ownersWebsite/config/OwnersDocsEdit'),
                meta: {
                    permissions: ['fivesclub_web_owners_show_owners_general_documents_edit_button']
                }
            },

        ]
    },
    { // web-owners requests
        path: '/fives-club/web-owners/requests',
        redirect: to => {
            let name = 'sales'
            const userPermissions = store?.state?.auth?.permissions
            if (!!userPermissions && userPermissions.length > 0) {
                if (userPermissions.includes('fivesclub_web_owners_show_maintenance_requests_list')) {
                    name = 'maintenance-requests'
                }
                else if (userPermissions.includes('fivesclub_web_owners_show_owner_transacctions_agreements_list')) {
                    name = 'owners-transactions-aceptance'
                }
            }
            return { name }
        },
        name: 'owners-website-requests',
        component: () => import(/*webpackChunkName: 'OwnersWebsiteRequests'*/'@/modules/fivesClub/views/ownersWebsite/OwnersWebsiteRequests'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_sub_menu_show_web_owners']
        },
        children:[
            {
                path: 'maintenance-requests',
                name: 'maintenance-requests',
                component: () => import(/*webpackChunkName: 'OwnersMaintenanceRequests'*/'@/modules/fivesClub/views/ownersWebsite/requests/OwnersMaintenanceRequests'),
                meta: {
                    permissions: ['fivesclub_web_owners_show_maintenance_requests_list']
                }
            },
            {
                path: 'maintenance-requests/request/:id',
                name: 'maintenance-request-detail',
                component: () => import(/*webpackChunkName: 'OwnersMaintenanceRequestsEdit'*/'@/modules/fivesClub/views/ownersWebsite/requests/OwnersMaintenanceRequestDetail'),
                meta: {
                    permissions: ['fivesclub_web_owners_show_maintenance_request_view']
                }
            },

            {
                path: 'owners-transactions-aceptance',
                name: 'owners-transactions-aceptance',
                component: () => import(/*webpackChunkName: 'OwnersTransactionsAceptance'*/'@/modules/fivesClub/views/ownersWebsite/requests/OwnersTransactionsAceptance'),
                meta: {
                    permissions: ['fivesclub_web_owners_show_owner_transacctions_agreements_list']
                }
            },
            {
                path: 'owners-transactions-aceptance/detail/:id',
                name: 'owners-transactions-aceptance-detail',
                component: () => import(/*webpackChunkName: 'OwnersTransactionsAceptanceDetail'*/'@/modules/fivesClub/views/ownersWebsite/requests/OwnersTransactionsAceptanceDetail'),
                meta: {
                    permissions: ['fivesclub_web_owners_show_owner_transacctions_agreements_details_view']
                }
            },
        ]
    },
    {
        path: '/fives-club/contractsInfo/:contracts',
        name: 'contractsInfo',
        component: () => import('@/modules/fivesClub/components/contracts/ListTransactions'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_sub_menu_show_contracts']
        },
        props: ( route ) => {
            return {
                contracts: route.params.contracts
            }
        },
    },
    { // owners-bookings
        path: '/fives-club/owners-bookings/',
        name: 'OwnersBooking',
        component: () => import(/*webpackChunkName: 'OwnersBookings'*/'@/modules/fivesClub/views/OwnersBookings'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_owner_booking_show_booking']
        },
        props: ( route ) => {
            return {
                index: route.params.index,
                bookingId: route.params.booking,
            }
        },


    },

    {
        path: '/fives-club/owner-booking/:booking',
        name: 'edit-owner-booking',
        component: () => import('@/modules/fivesClub/components/catalogs/ownersBookings/BookingRequests'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_owner_booking_show_booking']
        },
        props: ( route ) => {
            return {
                booking: route.params.booking
            }
        },
    },
    { // reports
        path: '/fives-club/reports',
        name: 'Reports',
        redirect: { name: 'transactions' },
        component: () => import(/*webpackChunkName: "Reports"*/'@/modules/fivesClub/views/reports/Reports'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_sub_menu_show_reports']
        },
        children:[
            {path: 'transactions', name: 'transactions', component: () => import(/*webpackChunkName: 'Transactions'*/'@/modules/fivesClub/views/reports/Transactions'), },
            {path: 'transactions-detail', name: 'transactions-detail', component: () => import(/*webpackChunkName: 'TransactionsDetail'*/'@/modules/fivesClub/views/reports/TransactionsDetail'), },
        ]
    },

    { // fees
        path: '/fives-club/fees',
        name: 'fees',
        redirect: { name: 'fees-list' },
        component: () => import(/*webpackChunkName: "ModulFees"*/'@/modules/fivesClub/views/profit/ModulFees'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_profit_sub_menu_show_profit']
        },
        children:[
            {path: '', name: 'fees-list', component: () => import(/*webpackChunkName: 'ListFees'*/'@/modules/fivesClub/views/profit/Fees/ListFees'), },
            {path: 'create', name: 'fee-create', component: () => import(/*webpackChunkName: 'FeesCreate'*/'@/modules/fivesClub/views/profit/Fees/FeesCreate'), meta: {
                permissions: ['fivesclub_profit_save_fee']
            }},
            {path: 'edit/:id', name: 'fee-edit', component: () => import(/*webpackChunkName: 'FeesEdit'*/'@/modules/fivesClub/views/profit/Fees/FeesEdit'), },
        ]
    },
    { // incomeExpenses
        path: '/fives-club/incomeExpenses',
        name: 'incomeExpenses',
        component: () => import(/*webpackChunkName: "ModulIncomeExpenses"*/'@/modules/fivesClub/views/profit/ModulIncomeExpenses'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_profit_sub_menu_show_profit']
        },
    },
    { // performanceConcepts
        path: '/fives-club/performanceConcepts',
        redirect: { name: 'concept-config' },
        name: 'performanceConcepts',
        component: () => import(/*webpackChunkName: "ModulPerformanceConcepts"*/'@/modules/fivesClub/views/profit/performanceConcepts/ModulPerformanceConcepts'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_profit_sub_menu_show_profit']
        },
        children:[
            {path: 'config', name: 'concept-config', component: () => import(/*webpackChunkName: 'PerformanceConcepts'*/'@/modules/fivesClub/views/profit/performanceConcepts/PerformanceConcepts'), },
            {path: 'config/create', name: 'concept-config-create', component: () => import(/*webpackChunkName: 'PerformanceConceptsCreate'*/'@/modules/fivesClub/views/profit/performanceConcepts/PerformanceConceptsCreate'), },
            {path: 'config/edit/:id', name: 'concept-config-edit', component: () => import(/*webpackChunkName: 'PerformanceConceptsEdit'*/'@/modules/fivesClub/views/profit/performanceConcepts/PerformanceConceptsEdit'), },

            {
                path: 'monetizing',
                name: 'monetizing-config',
                component: () => import(/*webpackChunkName: 'MonetizingConfig'*/'@/modules/fivesClub/views/profit/performanceConcepts/MonetizingConfig'),
                meta: {
                    requiresAuth: true,
                    permissions: ['fivesclub_profit_show_monetization_configuration_list']
                },
            },
            {
                path: 'monetizing/create',
                name: 'monetizing-config-create',
                component: () => import(/*webpackChunkName: 'MonetizingConfigCreate'*/'@/modules/fivesClub/views/profit/performanceConcepts/MonetizingConfigCreate'),
                meta: {
                    requiresAuth: true,
                    permissions: ['fivesclub_profit_allow_monetization_configuration_creation']
                },
            },
            {
                path: 'monetizing/edit/:id',
                name: 'monetizing-config-edit',
                component: () => import(/*webpackChunkName: 'MonetizingConfigEdit'*/'@/modules/fivesClub/views/profit/performanceConcepts/MonetizingConfigEdit'),
                meta: {
                    requiresAuth: true,
                    permissions: ['fivesclub_profit_show_monetization_configuration_detail_view']
                },
            },
        ]
    },
    { // modulCost
        path: '/fives-club/modulCost',
        redirect: { name: 'cost-monthly' },
        name: 'cost',
        component: () => import(/*webpackChunkName: "ModulCost"*/'@/modules/fivesClub/views/profit/Costs/ModulCost'),
        meta: {
            requiresAuth: true,
            permissions: ['fivesclub_profit_sub_menu_show_profit']
        },
        children:[
            { path: 'monthly', name: 'cost-monthly', component: () => import(/*webpackChunkName: 'Costs'*/'@/modules/fivesClub/views/profit/Costs/Costs'), },

            { path: 'yearly', name: 'cost-yearly', component: () => import(/*webpackChunkName: 'AnualPerformance'*/'@/modules/fivesClub/views/profit/Costs/AnualPerformance'), },
        ]
    },
]