export default {
    ordersOperation: [],
    categories: [],
    palces:[],

    filterO: {
        query: '',
    },
    isloadingProcess: false,
    selectedOrderOperation: null,
    suppliers: null,
    operators: null,
    updateStatus: false,
    orderDetail: null,
    incidents: [],
    errors: {
        ordersOperation: { error: false, message: '' },                     
        itineraryOrdersOperation: { error: false, message: '' },                     
        updateDataOperation: { error: false, message: '' },                     
        confirmDetailOrder: { error: false, message: '' },                     
        fetchSuppliers: { error: false, message: '' },                     
        fetchOperatorsBySupplier: { error: false, message: '' }, 
        updateSupplierOperator: { error: false, message: '' }, 
        updateItinerary: { error: false, message: '' },                         
    },
}