export default {
    transactions: [],
    detailTransactions: [],
    filter: {
        queryTransactions: '',
        queryDetailTransactions: '',
    },
    requestDetailTransactions: null,
    requestTransactions:null,
    benefits: []
}