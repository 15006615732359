import Vue from 'vue'

import { showAlertMessage, consoleMsgFinally, makeParamsFilterMySales, toJson } from '../../../helpers/helpers'
import { createItemsFromOrder, formatTransactions } from '../../../helpers/mySalesHelper'
import { formatBookings, formatRoomPromoCode, makePayloadRoomRate, formatOrderItems } from '../../../helpers/mySalesHelper'

import { fetchDepartaments, fetchAirlines, fetchDestinations, fetchPromoCodes, fetchCurrencies, fetchHotels, fetchStatus, fetchPaymentMethods, fetchTypeService, fetchItineraryRules } from '../../start/store/actions'
import store from '../../../store' //para acceder a state


export async function fetchUsersByChannel({ commit }, idCanal) {

    try {
        commit('mySales/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            url: '/catalogs/usuariosCanal/',
            params: {
                idCanal
            }
        })
        const payload = response && response.data

        payload.forEach((user) => {
            user.value = user.id,
            user.fullname = user.first_name + ' ' + user.last_name
        })

        payload.sort(function(a, b){
            if(a.fullname < b.fullname) { return -1}
            if(a.fullname > b.fullname) { return 1}
            return 0
        })

        // payload.push({ value: 0, fullname: 'Todos los usuarios', is_active: true })
        commit('mySales/setUsersChannel', payload, { root: true })
        commit('mySales/setActiveUsers', payload, { root: true })

    } catch (error) {
        console.log( error )
    } finally {
        commit('mySales/setLoadingProcess', false, { root: true })
        consoleMsgFinally('mySales/fetchUsersByChannel', 'La petición para obtener los usuarios del canal se ha terminado')
    }
}
//peticiones para obtener lista de ordenes por usuario
export async function fetchOrdersByUser(context, data) {
    // const { inicio, fin, idUser, tipoFecha, canalVenta } = data
    const parametros = makeParamsFilterMySales( data ) // creo los parametros

    try {
        context.commit('mySales/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            url: `/orders/?`,
            params: {
                ...parametros
            }
        })
        const payload = response && response.data

        const {orders, ordersItems} = formatOrderItems(payload)

        context.commit('mySales/setOrders', orders, { root: true })
        context.commit('mySales/setOrdersItems', ordersItems, { root: true })
    } catch (error) {
        console.log( error )
    } finally {
        context.commit('mySales/setLoadingProcess', false, { root: true })
        consoleMsgFinally('mySales/fetchOrdersByUser', 'La petición para obtener las ordenes se ha terminado')
    }
}

export async function fetchSingleOrder(context, data) {
    // const { inicio, fin, idUser, tipoFecha, canalVenta } = data
    const parametros = makeParamsFilterMySales( data ) // creo los parametros
    try {
        const response = await Vue.axios({
            url: `/orders/?`,
            params: {
                ...parametros
            }
        })
        const payload = response && response.data
        return payload
    } catch (error) {
        console.log( error )
        return []
    } finally {
        consoleMsgFinally('mySales/fetchSingleOrder', 'La petición para obtener la orden slicitada se ha terminado')
    }
}

export async function getInitialDataOrderDetails( context, payload ){
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }
    const content = await Promise.all([
        fetchOrderDetail(context, payload), fetchTransactionsOrder(payload), fetchDepartaments(), fetchHotels(payloadHotels), fetchStatus(), fetchPaymentMethods() //make 6 requests
    ])
    const [ orderDetail, transactions, departaments, hotels, status, paymentMethods ] = content
    orderDetail.transactions = transactions // agrego las transacciones si viene al order detail
    context.commit('start/setHotels', hotels, { root: true }) //muto state hotels en start
    context.commit('setOrderDetail', orderDetail ) //muto state orderDetail en mySales
    context.commit('start/setDepartaments', departaments, { root: true }) //muto state departaments en start
    context.commit('start/setStatus', status, { root: true }) //muto state Status en start
    context.commit('start/setPaymentMethods', paymentMethods, { root: true }) //muto state paymentMethods en start

    return { orderDetail, transactions, departaments }
}

export async function getAirlinesAndDestinations(context){
    const content = await Promise.all([
        fetchAirlines(), fetchDestinations(), //make 2 requests
    ])
    const [ airlines, destinations ] = content
    context.commit('start/setAirlines', airlines, { root: true }) //muto state airlines en start
    context.commit('start/setDestinations', destinations, { root: true }) //muto state destinations en start
    return content
}

export async function getServiceTypesAndItineraryRules(context){
    const content = await Promise.all([
        fetchTypeService(), fetchItineraryRules(), //make 2 requests
    ])
    const [ TypeService, ItineraryRules ] = content
    context.commit('start/setTypeService', TypeService, { root: true }) //muto state TypeService en start
    context.commit('start/setItineraryRules', ItineraryRules, { root: true }) //muto state ItineraryRules en start
}

export async function getPromocodesAndCurrencies(context){
    const content = await Promise.all([
        fetchPromoCodes(), fetchCurrencies(), //make 2 requests
    ])
    const [ promoCodes, currencies ] = content
    context.commit('start/setPromoCodes', promoCodes, { root: true }) //muto state promocodes en start
    context.commit('start/setCurrencies', currencies, { root: true }) //muto state currencies en start
    return content
}

export async function fetchOrderDetail(context, payload) {
    const { idOrder } = payload
    try {
        const response = await Vue.axios({
            url: `/ordersdetail/`,
            params: {
                ido: idOrder
            }
        })
        const payload = response && response.data
        return createItemsFromOrder( payload )
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('mySales/fetchOrderDetail', 'La petición fetchOrderDetail ha terminado')
    }
}

export async function fetchTransactionsOrder(payload) {
    const { idOrder } = payload
    try {
        const response = await Vue.axios({
            url: `/payment/orderTransactions/?`,
            params: {
                order: idOrder
            }
        })
        const payload = response && response.data
        return formatTransactions( payload )
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('mySales/fetchTransactionsOrder', 'La petición para obtener las transacciones de la orden se ha terminado')
    }
}

export async function fetchSingleTransactionsOrder(context, idOrder) {
    try {
        const response = await Vue.axios({
            url: `/payment/orderTransactions/?`,
            params: {
                order: idOrder
            }
        })
        const payload = response && response.data
        context.commit('setCurrentTransactions', formatTransactions( payload ) )
        return formatTransactions( payload )
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('mySales/fetchTransactionsOrder', 'La petición para obtener las transacciones de la orden seleccionada se ha terminado')
    }
}

export async function updateReference(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/orders/',
            data: payload
        })
       
        const respuesta = response && response.data
        const { status } = respuesta

        if( status ){
            showAlertMessage('Ok', 'BellIcon', `🎉🎉!Datos actualizados !.`, 'success', 4000, 'bottom-right')
        }
    } catch (error) {
        consoleMsgFinally('mySales/updateItinerary', error )
    } finally {
        consoleMsgFinally('mySales/updateItinerary', 'La petición para la actualización de datos referencia finalizó')
    }
}

export async function updateCustomer(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: '/customers/',
            data: payload
        })
        const respuesta = response && response.data
        // const { status } = respuesta
        if (respuesta) showAlertMessage('Ok', 'BellIcon', `🎉🎉¡Datos actualizados!`, 'success', 4000, 'bottom-right')
        return true
    } catch (error) {
        consoleMsgFinally('mySales/updateCustomer', error )
        const respuesta = (error?.response && error?.response?.data) || "No se ha actualizado el usuario, por favor intentelo más tarde"
        showAlertMessage( "Error: proceso finalizado", "InfoIcon", respuesta, "danger", 4000, "bottom-right" )
        return false
    } finally {
        consoleMsgFinally('mySales/updateCustomer', 'La petición para la actualización del cliente finalizó')
    }
}

export async function resendEmail(context, order) {
    const urlSendMail = process.env.VUE_APP_ORDER_RESEND_EMAIL
    try {
        const response = await Vue.axios({
            url:`${urlSendMail}${order}/`,
        })
        const respuesta = response && response.data

        const { status, msj } = respuesta

        if( status ){
            showAlertMessage('', 'BellIcon', `🎉🎉 ${msj}`, 'success', 4000, 'bottom-right')
        }

        if( !status ){
            showAlertMessage('Error', 'BellIcon', `😪😪 ${msj}`, 'danger', 4000, 'bottom-right')
        }

    } catch (error) {
        consoleMsgFinally('mySales/resendEmail', error )
    } finally {
        consoleMsgFinally('mySales/resendEmail', 'La petición para el reenvío de correo al cliente finalizó')
    }
}

export async function fetchDataBooking({ commit }, datos) {
    const { id, initialPrice } = datos
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/bookAvailable/`,
            data: datos
        })
        const respuesta = response && response.data

        const { status, data } = respuesta
        if (status && data.length > 0) {
            const bookingData = JSON.parse(JSON.stringify(respuesta.data))
            const lengthRate = respuesta.data[0].rates.length
            if (lengthRate == 0) {
                showAlertMessage('Busqueda terminada', 'BellIcon', `No se encontraron tarifas, intente otra búsqueda`, 'warning', 3000, 'bottom-right')
            }
            let defaultCurrency = store?.state?.start?.currencies?.find(currency => currency.currencyid == 1)
            if (!defaultCurrency) defaultCurrency = { currencyid: 1, code: "USD", value: "1.0000" }

            bookingData[0].divisa = defaultCurrency
            bookingData[0].hasOneRate = lengthRate > 0 ? true : false
            bookingData[0].selectedRate = respuesta.data[0].rates[0]
            bookingData[0].allowPriceMatch = true
            bookingData[0].priceMatchTax = lengthRate > 0 ? respuesta.data[0].rates[0].AmountAfterTax : ''
            bookingData[0].showingRateDiscount = true
            bookingData[0].showingRatePriceMatch = true
            bookingData[0].initialPrice = initialPrice
            bookingData[0].manualPrice = ''

            const data = { bookingData: bookingData[0], id }
            commit('mySales/setRoomBooking', data, { root: true })
        } else {
            showAlertMessage('Busqueda terminada', 'BellIcon', `😔 No se encontraron tarifas, intente otra búsqueda`, 'warning', 3000, 'bottom-right')
            const data = { bookingData: null, id }
            commit('mySales/setRoomBooking', data, { root: true })
        }
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('mySales/fetchDataBooking', 'La petición para obtener los datos del booking ha terminado')
    }
}

export async function getRooms(context, datos) {
    datos.currency = store.state.shop?.currency?.code
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/bookAvailable/`,
            data: datos
        })
        const respuesta = response && response.data        
        const { status, isPromo } = respuesta
        if( status ){
            let payload = JSON.parse(JSON.stringify( respuesta.data ))
            
            if( payload && payload.length > 0  ){                
                return formatBookings( payload, isPromo, datos )
            } else {                
                return  []
            }
        } else {
            return []
        }

    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('mySales/getRooms', 'La petición para obtener los datos ha terminado')
    }
}

export async function fetchRoomPriceMatch(context, datos) {
    const previousRooms = toJson(store.state.mySales.bookingSelected.rooms)
    // console.log( previousRooms )
    try {

        const response = await Vue.axios({
            method: 'POST',
            url: `/bookAvailable/`,
            data: datos
        })
        const respuesta = response && response.data
        const { status, isPromo, message } = respuesta
        if( status ){
            let payload = JSON.parse(JSON.stringify( respuesta.data ))
            if( payload.length > 0 ){
                const newRoom = formatRoomPromoCode( payload[0], datos, message, isPromo )
                return makePayloadRoomRate( { keyRoom: datos.keyRoom, newRoom, previousRooms } )
            } else {
                return  []
            }
        } else {
            return []
        }
    } catch (error) {
        console.log(error)
    } finally {

        consoleMsgFinally('mySales/fetchRoomPriceMatch', 'La petición ha terminado')
    }
}

export async function validateUser(context, datos) {
    const { username, canalVenta } = datos
    try {
        const response = await Vue.axios({
            url: `/authlogin/`,
            params: { username, canalVenta }
        })
        const respuesta = response && response.data
        const { status } = respuesta
        if (status) {
            const { id } = respuesta
            return { idUser: id, isInvalid: false }
        } else {
            return { idUser: null, isInvalid: true }
        }
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('mySales/validateUser', 'La petición validateUser ha finalizado')
    }
}

export async function authorizeRateUser(context, datos) {
    const { username, file } = datos
    // console.log(datos, file )
    try {
        const formData = new FormData()
        file.forEach(files => {
            formData.append('file', files)
        })
        formData.append('data', JSON.stringify(datos))
        // console.log(formData)
        const response = await Vue.axios({
            method: 'POST',
            url: `/authlogin/`,
            data: formData
        })
        const respuesta = response && response.data
        const { status, urlAuthorization } = respuesta
        if (status) return urlAuthorization
        else return { url: null }

    } catch (error) {
        if (error.response) {
            if (error.response.statusText == 'Unauthorized') {
                showAlertMessage('Código incorrecto', 'InfoIcon', `😪😪 El código para su usuario ${username} es incorrecto`, 'danger', 4000, 'bottom-right')
                return false
            }
            if (error.response.statusText == 'Bad Request') {
                showAlertMessage('Bad Request', 'InfoIcon', `😪😪 Petición invalida`, 'danger', 4000, 'bottom-right')
                return false
            }
        }
    } finally {
        consoleMsgFinally('mySales/authorizeRateUser', 'La petición authorizeRateUser ha finalizado')
    }
}

export async function updateBooking(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/orderdetailsbooking/',
            data: payload
        })
        const respuesta = response && response.data
        const { status } = respuesta
        if (status) {
            const message = `🎉🎉La reserva ha sido sido actualizada.`
            return {status, message }
        } else {
            const message = `😪😪 Ocurrió un error con la actualización, intenta de nuevo o contacta a sistemas.`
            return {status, message}
        }
    } catch (error) {
        console.log(error)
        const message = `😪😪 Ocurrió un error con la actualización, intenta de nuevo o contacta a sistemas.`
        return {status:false, message}
    } finally {
        consoleMsgFinally('mySales/updateBooking', 'La petición updateBooking finalizó')
    }
}

export async function updateBookingOwner(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/updatebookingowner/',
            data: payload
        })
        const respuesta = response && response.data
        const { status } = respuesta
        if (status) showAlertMessage('OK', 'BellIcon', `🎉🎉La información de los huéspedes ha sido sido actualizada.`, 'success', 4000, 'bottom-right')
        else showAlertMessage('Error', 'InfoIcon', `😪😪 Ocurrió un error con la actualización.`, 'danger', 4000, 'bottom-right')
        return status
    } catch (error) {
        console.log(error)
        showAlertMessage('Error', 'InfoIcon', `😪😪 Ocurrió un error con la actualización.`, 'danger', 4000, 'bottom-right')
        return false
    } finally {
        consoleMsgFinally('mySales/updateBookingOwner', 'La petición para la actualización de los huéspedes finalizó')
    }
}

export async function updateBeneficiary(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/beneficiary/',
            data: payload
        })
        const respuesta = response && response.data
        const { status } = respuesta
        if (status) {
            showAlertMessage(
                'OK',
                'BellIcon',
                `🎉🎉La información del beneficiario ha sido sido actualizada.`,
                'success',
                4000,
                'bottom-right'
            )
        } else {
            showAlertMessage('Error', 'InfoIcon', `😪😪 Ocurrió un error con la actualización.`, 'danger', 4000, 'bottom-right')
        }
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('mySales/updateBeneficiary', 'La petición para la actualización del beneficiario finalizó')
    }
}


export async function updateOperationDateTimeNote({ commit }, payload) {
    const { orderdetail } = payload
    try {
        commit('mySales/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
                method: 'POST',
                url: '/ordersdetail/?',
                params: {
                    ido: orderdetail
                },
                data: payload
            })
            // console.log(response)
        const respuesta = response && response.data
        const { status } = respuesta
        if (status) {
            showAlertMessage('OK', 'BellIcon', `🎉🎉Los datos han sido actualizados.`, 'success', 4000, 'bottom-right')
        } else {
            showAlertMessage('Error', 'BellIcon', `😪😪 Ocurrió un error con la actualización.`, 'daanger', 4000, 'bottom-right')
        }
    } catch (error) {
        console.log( error )
    } finally {
        commit('mySales/setLoadingProcess', false, { root: true })
        consoleMsgFinally('mySales/updateOperationDateTimeNote', 'La petición para la actualización de la información finalizó')
    }
}

export async function updateItineraryDetail({ commit }, payload) {

    try {
        commit('mySales/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            method: 'POST',
            url: '/ordersdetailitinerary/',
            data: payload
        })
        const { status, message } = response && response.data
        if (status) showAlertMessage('Ok', 'BellIcon', `🎉🎉 ${message}.`, 'success', 4000, 'bottom-right')
        return status

    } catch (error) {
        console.log( error )
        const { status, message } = error.response && error.response.data
        showAlertMessage('Error', 'BellIcon', `😪😪 Ocurrió un error con la actualización.`, 'danger', 4000, 'bottom-right')
        console.warn('mySales/updateItineraryDetail: '+message)
        return status

    } finally {
        commit('mySales/setLoadingProcess', false, { root: true })
        consoleMsgFinally('mySales/updateItineraryDetail', 'La petición para la actualización del itinerario finalizó')
    }
}

export async function updateBEOProduct(context, payload) {

    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/orderdetailsbeo/',
            data: payload
        })
        const respuesta = response && response.data
        const { status } = respuesta
        if (status) {
            showAlertMessage('Ok', 'BellIcon', `🎉🎉Los datos para el BEO del item han sido actualizados.`, 'success', 4000, 'bottom-right')
        } else {
            showAlertMessage('Error', 'BellIcon', `😪😪 Ocurrió un error con la actualización.`, 'danger', 4000, 'bottom-right')
        }
    } catch (error) {
        console.log( error )
    } finally {
        consoleMsgFinally('mySales/updateBEOProduct', 'La petición para la actualización del BEO finalizó')
    }
}


export async function updateItinerary({ commit }, payload) {
    try {
        commit('setLoading', true, { root: true })
        const response = await Vue.axios({
            method: 'POST',
            url: '/ordertransfersupdate/',
            data: payload
        })
        const respuesta = response && response.data

        commit('operation/updateInfoValid', respuesta.status, { root: true })
        commit('setLoading', false, { root: true })
        showAlertMessage('Ok', 'BellIcon', `🎉🎉!Datos de itineario actualizado !.`, 'success', 4000, 'bottom-right')
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('mySales/updateItinerary', 'La petición para la actualización del itinerario finalizó')
    }
}

export async function fetchTransferDetail({ commit }, payload) {
    const { idOrder } = payload
    try {
        commit('setLoading', true, { root: true })
        const response = await Vue.axios({
            url: `/ordertransfer/?ido=${idOrder}`,
        })
        const payload = response && response.data
        commit('mySales/setDetailOrder', payload, { root: true })

    } catch (error) {
        console.log( error )
    } finally {
        commit('setLoading', false, { root: true })
        consoleMsgFinally('mySales/fetchTransferDetail', 'La petición para la actualización del itinerario finalizó')
    }
}


export async function fetchDataurlBank({ commit }, order) {

    try {
        const response = await Vue.axios({
            url: `payment/generateurlbank/?order=${order}&requesturlbank=1`,
        })
        const payload = response && response.data
        // console.log(payload);
        commit('mySales/setDataPaymentLink', payload, { root: true })

    } catch (error) {
        console.log( error )
    } finally {
        console.log('La petición para paymentlink finalizó')
        consoleMsgFinally('mySales/setDataPaymentLink', 'La petición para la paymentlink finalizó')
    }
}

export async function fetchDataTransaction({ commit }, order) {

    try {
        const response = await Vue.axios({
            url: `payment/orderTransactions/?order=${order}`,
        })
        const payload = response && response.data
        commit('mySales/setDataTransaction', payload, { root: true })

    } catch (error) {
        console.log( error )
    } finally {
        console.log('La petición para la Transaction finalizó')
        consoleMsgFinally('mySales/fetchDataTransaction', 'La petición para la Transaction finalizó')
    }
}

export async function fetchTransactionPay({ commit }, payload) {

    try {
        const response = await Vue.axios({
            url: `payment/linkParcials/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        // console.log(respuesta);
        if (!!respuesta.url) {
            showAlertMessage('Ok', 'BellIcon', `🎉🎉The payment request has been sent`, 'success', 4000, 'bottom-right')
        } else {
            showAlertMessage('Sorry', 'BellIcon', `The payment request hasn't been sent, please try again later`, 'warning', 4000, 'bottom-right')
        }

        commit('mySales/setDataPaymentLink', respuesta, { root: true })

    } catch (error) {
        console.log( error )
        showAlertMessage('Sorry', 'BellIcon', `The payment request hasn't been sent, please try again later`, 'warning', 4000, 'bottom-right')
    } finally {
        console.log('La petición para paymentlink finalizó')
        consoleMsgFinally('mySales/setDataPaymentLink', 'La petición para la paymentlink finalizó')
    }

    // try {
    //     commit('mySales/setLoadingProcess', true, { root: true })
    //     const response = await Vue.axios({
    //         url: `payment/linkParcials/?order=${order}&&amount=${amount}&&detail=${detail}`,
    //     })
    //     const payload = response && response.data

    //     commit('mySales/setTransactionPay', payload, { root: true })

    //     showAlertMessage('Ok', 'BellIcon', `🎉🎉The payment request has been sent`, 'success', 4000, 'bottom-right')

    // } catch (error) {
    //     console.log( error )
    // } finally {
    //     commit('mySales/setLoadingProcess', false, { root: true })
    //     consoleMsgFinally('mySales/fetchTransactionPay', 'La petición para la TransactionPay finalizó')
    // }
}

export async function getFileFromOrder(context, id) {
    try {
        const response = await Vue.axios({
            url: `/ordersfiles/`,
            params: {
                idOrder: id
            }
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('mySales/getFileFromOrder', 'La petición para solicitar los archivos de evidencias ha terminado')
    }
}

export async function uploadFileFromOrder(context, payload) {
    const { file } = payload
    try {
        const formData = new FormData()
        file.forEach(files => {
            formData.append('file', files)
        })
        formData.append('data', JSON.stringify(payload))
        const response = await Vue.axios({
            method: 'POST',
            url: `/ordersfiles/`,
            data: formData
        })
        const respuesta = response && response.data
        if ( respuesta && respuesta.status ) {
            showAlertMessage('Ok', 'BellIcon', `🎉🎉 El archivo ha sido cargado`, 'success', 4000, 'bottom-right')
            return respuesta.status
        }
    } catch (error) {
        console.log(error)
        showAlertMessage('Advertencia', 'BellIcon', `El archivo no ha podido cargarse`, 'danger', 4000, 'bottom-right')
        return false
    } finally {
        consoleMsgFinally('mySales/uploadFileFromOrder', 'La petición para subir un archivo de evidencias ha terminado')
    }
}

export async function deleteFileFromOrder(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/ordersfiles/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        if ( respuesta && respuesta.status ) {
            showAlertMessage('Ok', 'BellIcon', `🎉🎉 El archivo ha sido borrado`, 'success', 4000, 'bottom-right')
            return respuesta.status
        }
    } catch (error) {
        console.log(error)
        showAlertMessage('Advertencia', 'BellIcon', `El archivo no ha podido borrarse`, 'danger', 4000, 'bottom-right')
        return false
    } finally {
        consoleMsgFinally('mySales/deleteFileFromOrder', 'La petición para borrar un archivo de evidencias ha terminado')
    }
}

export async function fetchCancellationReasons(context, id) {
    try {
        const response = await Vue.axios({
            url: '/catalogs/cancelMotiveList/',
            params: {
                idCanal: id
            }
        })
        const respuesta = response && response.data

        context.commit('setCancellationReasons', respuesta) //muto state cancellationReasons

    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchCancellationReasons end')
    }
}

export async function nullOrder(context, payload) {
    const {id, reason, idUser} = payload
    try {
        const response = await Vue.axios({
            method: 'POST',
            url:'/nullorder/',
            data:{
                order_id: id,
                user_id: idUser,
                cancelmotive_id: reason,
            }
        })
        const respuesta = response && response.data
        const { status, msj } = respuesta

        if( status ) showAlertMessage('', 'BellIcon', `🎉🎉 ${msj}`, 'success', 4000, 'bottom-right')

        return status

    } catch (error) {
        console.log(error)
        const {status, msj, errors} = error.response && error.response.data
        if (msj) {
            showAlertMessage("Error", "InfoIcon", `😪😪 ${msj} Intentelo más tarde.`, "danger", 4000, "bottom-right")
            console.warn(msj)
            if (errors) console.warn(errors)
        }
        return status
    } finally {
        console.log('fetchCancellationReasons end')
    }
}

export async function reactivateOrder(context, payload) {
    const {id, reason, idUser} = payload
    try {
        const response = await Vue.axios({
            method: 'POST',
            url:'/reactivateorder/',
            data:{
                order_id: id,
                user_id: idUser,
                cancelmotive_id: reason,
            }
        })
        const respuesta = response && response.data
        const { status, msj } = respuesta

        if( status ) showAlertMessage('', 'BellIcon', `🎉🎉 ${msj}`, 'success', 4000, 'bottom-right')

        return status

    } catch (error) {
        console.log(error)
        const {status, msj, errors} = error.response && error.response.data
        if (message) {
            showAlertMessage("Error", "InfoIcon", `😪😪 ${msj} Intentelo más tarde.`, "danger", 4000, "bottom-right")
            console.warn(msj)
            if (errors) console.warn(errors)
        }
        return status
    } finally {
        console.log('fetchCancellationReasons end')
    }
}

export async function fetchHistoryLogs(context, payload) {
    try {
        const response = await Vue.axios({
            url:'/bitacoraLogs/',
            params:{
                ...payload
            }
        })
        const respuesta = response && response.data

        return respuesta?.length > 0 ? respuesta : null

    } catch (error) {
        console.log(error)
        const errors = error.response && error.response.data
        if (errors) console.warn(errors)
        return false
    } finally {
        console.log('fetchCancellationReasons end')
    }
}


export async function updateUpgrades(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: '/upgrades/',
            data: payload
        })
        const respuesta = response && response.data
        // const { status } = respuesta
        if (respuesta) showAlertMessage('Ok', 'BellIcon', `🎉🎉¡Datos actualizados!`, 'success', 4000, 'bottom-right')
        return true
    } catch (error) {
        consoleMsgFinally('mySales/updateCustomer', error )
        const respuesta = (error?.response && error?.response?.data) || "No se ha actualizado el usuario, por favor intentelo más tarde"
        showAlertMessage( "Error: proceso finalizado", "InfoIcon", respuesta, "danger", 4000, "bottom-right" )
        return false
    } finally {
        consoleMsgFinally('mySales/updateUpgrades', 'La petición para la actualización del cliente finalizó')
    }
}

