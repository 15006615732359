import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

const overWriteAxiosHeaders = (token) =>{
    //sobreescribo los datos en axios, una vez obtenido un token
    axios.defaults.headers.common['Authorization'] = 'Token '+ token
    Vue.use(VueAxios, axios)// end
}

const generateNavigationUser = ( permissions ) => {
    let navigation = []
    if(permissions.includes('marketplace_menu_show_marketplace')){
        navigation.push({ key: 1, title: 'Marketplace', route: 'sales', icon: 'ShoppingCartIcon' })
    }
    if(permissions.includes('bookings_menu_show_booking')){
        navigation.push({ key: 2, title: 'Estancias', route: 'booking', icon: 'HomeIcon' })
    }
    if(permissions.includes('my_sales_menu_show_my_sales')){
        navigation.push({ key: 3, title: 'Mis ventas', route: 'my-sales', icon: 'BarChart2Icon',})
    }
    if(permissions.includes('operation_menu_show_operation')){
        navigation.push({ key: 4, title: 'Operación', route: 'operations', icon: 'CommandIcon',})
    }
    if(permissions.includes('groups_menu_show_group')){
        navigation.push({ key: 5, title: 'Grupos', route: 'groups', icon: 'UsersIcon' })
    }
    if(permissions.includes('faq_menu_show_faq')){
        navigation.push({ key: 6, title: 'FAQ', route: 'faq', icon: 'HelpCircleIcon', })
    }
    const fivesClubsubMenus = permissions.filter( menuFivesClub => menuFivesClub.toLowerCase().includes('fivesclub_sub_menu') )//verifico si viene permisos que indiquen menus fivesclub

    if(fivesClubsubMenus.length > 0 ){
        let fivesClubMenu = { key: 7, title: 'Fives Club', route: 'housing', icon: 'UserIcon', tagVariant: 'light-primary'}
        let subFivesClubMenu = { key: 8, title: 'Rendimientos', route: 'housing', icon: 'UserIcon', tagVariant: 'light-primary'}
        let webOwnersMenu = { key: 8, title: 'Web Propietarios', tagVariant: 'light-primary'}

        fivesClubMenu.children = []
        subFivesClubMenu.children = []
        webOwnersMenu.children = []
        navigation.push( fivesClubMenu )

        if(permissions.includes('fivesclub_owner_booking_show_booking')){
            fivesClubMenu.children.push({ title: 'Booking propietarios',  route: { name: 'OwnersBooking', params: { index: 0 } }  })
        }
        if(permissions.includes('fivesclub_sub_menu_show_benefits')){
            fivesClubMenu.children.push({ title: 'Beneficios', route: 'benefits' })
        }
        if(permissions.includes('fivesclub_sub_menu_show_blackouts')){
            fivesClubMenu.children.push({ title: 'Blackouts', route: 'blackouts' })
        }
        if(permissions.includes('fivesclub_sub_menu_show_housing')){
            fivesClubMenu.children.push(
                { title: 'Viviendas', route: 'housing' },
                { title: 'Condominios', route: 'condominium' },
                { title: 'Pool de Rentas', route: 'rental-pool' }
            )
        }

        if(permissions.includes('fivesclub_owners_show_owners')){
            fivesClubMenu.children.push({ title: 'Propietarios', route: 'customers' })
        }
        if(permissions.includes('fivesclub_membership_show_membership')){
            fivesClubMenu.children.push({ title: 'Membresías', route: 'memberships' })
        }
        if(permissions.includes('fivesclub_subscriptions_show_exchanges_list')){
            // if(permissions.includes('fivesclub_subscription_show_subscription')){
        }
        fivesClubMenu.children.push({ title: 'Suscripciones', route: 'subscriptions' })
        if(permissions.includes('fivesclub_sub_menu_show_contracts') || permissions.includes('fivesclub_contracts_show_contracts')){
            fivesClubMenu.children.push({ title: 'Contratos', route: 'contracts' })
        }
        if(permissions.includes('fivesclub_sub_menu_show_web_owners')){
            fivesClubMenu.children.push(webOwnersMenu)
            webOwnersMenu.children.push(
                { title: 'Configuraciones', route: 'owners-website-config' },
            )
            if(
                permissions.includes('fivesclub_web_owners_show_maintenance_requests_list') ||
                permissions.includes('fivesclub_web_owners_show_owner_transacctions_agreements_list')
            ){
                webOwnersMenu.children.push({ title: 'Solicitudes', route: 'owners-website-requests' })
            }
        }
        if(permissions.includes('fivesclub_sub_menu_show_reports')){
            fivesClubMenu.children.push({  title: 'Reportes', route: 'Reports' })
        }
        if(permissions.includes('fivesclub_profit_sub_menu_show_profit')){
            fivesClubMenu.children.push(subFivesClubMenu)
            subFivesClubMenu.children.push(
                { title: 'Configuración', route: 'performanceConcepts' },
                { title: 'Fees', route: 'fees' },
                { title: 'Ingresos y gastos', route: 'incomeExpenses' },
                { title: 'Cálculo de rendimiento', route: 'cost' }
            )
        }
    }

    const guestPagerPermissions = permissions.includes('consumption_centers_menu_show_consumption_centers')

    if(guestPagerPermissions){
        let guestPagerMenu = { key: 9, title: 'Centros de Consumo', route: 'waiting-list', icon: 'ShoppingBagIcon', tagVariant: 'light-primary'}
        guestPagerMenu.children = []
        navigation.push( guestPagerMenu )

        // if(permissions.includes('consuption_centers_waiting_list_show_waiting_list')){
        guestPagerMenu.children.push({ title: 'Lista de Espera', route: 'waiting-list' })
        // }
    }

    return navigation
}
export { generateNavigationUser, overWriteAxiosHeaders }