import Vue from 'vue'

import { addIsLoadingToArray, formatAddressCondos } from '@/helpers/fivesClubHelper'
import { showAlertMessage, consoleMsgFinally } from '@/helpers/helpers'
import * as moment from 'moment'
import { fetchHotels, fetchLanguages } from '@/modules/start/store/actions'
import { getDocumentsTypeForContracts } from '@/modules/fivesClub/store/contracts/actions'

export async function getInitialContentFivesClub(context) {
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }
    const content = await Promise.all([
        fetchHotels(payloadHotels), fetchCancellationReasons()
    ])
    const [ hotels, cancellationReasons ] = content
    context.commit('start/setHotels', hotels, { root: true }) //muto state hotels en start      
    context.commit('setCancellationReasons', cancellationReasons) //muto state cancellationReasons     
    return content
}

export async function getInitialContentOwners(context, required) {
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }

    if (required?.hotels) {
        const hotels = await fetchHotels(payloadHotels)
        context.commit('start/setHotels', hotels, { root: true }) //muto state hotels en start
    }
    if (required?.languages) {
        const languages = await fetchLanguages()
        context.commit('start/setLanguages', languages, { root: true }) //muto state languages
    }
    if (required?.nationalities) {
        const nationalities = await fetchNacionality()
        context.commit('setNacionality', nationalities) //muto state nationalities
    }
    return true
}

export async function getInitialContentForBenefits(context, required) {

    if (required?.typeMemberships) {
        const typeMemberships = await fetchTypesMemberships()
        context.commit('setTypeMemberships', typeMemberships)
    }
    if (required?.typesBenefits) {
        const typesBenefits = await fetchTypesBenefits()
        context.commit('setTypesBenefits', typesBenefits)
    }
    if (required?.groupsTypesBenefits) {
        const groupsTypesBenefits = await fetchGroupsTypesBenefits()
        context.commit('setGroupsTypesBenefits', groupsTypesBenefits)
    }
    if (required?.paymentTypes) {
        const paymentTypes = await fetchPaymentMethods()
        context.commit('setPaymentMethods', paymentTypes)
    }
    if (required?.categoriesList) {
        const categoriesList = await fetchCategories()
        context.commit('setListCategories', categoriesList)
    }
}

export async function getInitialContentHousing(context) {
    if (required?.condos) {
        const condos = await fetchCondos()
        context.commit('setCondos', condos)
    }
    if (required?.subCondos) {
        const subCondos = await fetchSubCondos()
        context.commit('setSubCondos', subCondos)
    }
    if (required?.rentalPool) {
        const rentalPool = await fetchGetRentalPool(context)
        context.commit('setRentalPool', rentalPool)
    }

    return true
}

export async function getInitialContentListHousing(context) {
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }

    const content = await Promise.all([
        fetchCondos(), fetchSubCondos(), fetchHotels(payloadHotels), fetchGetRentalPool(context), fetchPaymentTypes()
    ])
    const [condos, subCondos, hotels, rentalPool, paymentTypes ] = content
    
    return { condos, subCondos, hotels, rentalPool, paymentTypes }
}

export async function getInitialContentRentalPool(context) {
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }

    const content = await Promise.all([
        fetchCondos(), fetchSubCondos(), fetchHotels(payloadHotels), fetchGetRentalPool(context)
    ])

    const [condos, subCondos, hotels, rentalPool ] = content
    
    return { condos, subCondos, hotels, rentalPool }
}

export async function getInitialContentEditCondominium(context) {
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }

    const content = await Promise.all([
        fetchHotels(payloadHotels)
    ])

    const [ hotels ] = content
    
    return { hotels }
}

// Web Owners

export async function getInitialContentForNewsletter(context) {    
    const content = await Promise.all([
  
        fetchNewsLetters(), fetchLanguages()
    ])
    const  [newsLetters, languages ] = content 
    return { newsLetters, languages}
}

export async function getInitialContentForHotelPerformance(context) {  
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }

    const content = await Promise.all([
        fetchHotels(payloadHotels), fetchLanguages(), fetchHotelPerformance()
    ])
    const  [hotels, languages, hotelsPerformance ] = content 
    return { hotels, languages, hotelsPerformance }
}

export async function getInitialContentForTheFivesVacations(context) {  
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }

    const content = await Promise.all([
        fetchHotels(payloadHotels), fetchLanguages(), fetchSocialNetworks()
    ])
    const  [hotels, languages, socialNetworks ] = content
    return { hotels, languages,socialNetworks }
}

export async function getInitialContentForOwnersTransactionsAceptance(context, required) {
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }

    if (required?.hotels) {
        const hotels = await fetchHotels(payloadHotels)
        context.commit('start/setHotels', hotels, { root: true }) //muto state hotels en start
    }
    if (required?.memberships) {
        const memberships = await fetchMembershipsInfo(context, {typeReq: 'title'})
        context.commit('setMembershipsTitles', memberships)
    }

    return true
}

export async function getInitialContentForStaffContact(context) {
    const content = await Promise.all([
        fetchStaffContact()
    ])
    const  [staffContact ] = content
    return { staffContact }
}

export async function getInitialContentWeb(context) {
    const content = await Promise.all([
      
        fetchNewsLetters(), fetchHotelPerformance(), fetchLanguages()
    ])
    const [newsLetters,hotelImg,languages ] = content
    context.commit('start/setLanguages', languages, { root: true }) //muto state languages     
    context.commit('setNewsLetters', newsLetters)
    context.commit('setHotelImg', hotelImg)
    
    return content
}

export async function getInitialContentForGeneralDocuments(context, required) {
    if (required?.memberships) {
        const memberships = await fetchMembershipsInfo(context, {typeReq: 'title'})
        context.commit('setMembershipsTitles', memberships)
    }
    if (required?.hotels) {
        const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }

        const hotels = await fetchHotels(payloadHotels)
        context.commit('start/setHotels', hotels, { root: true })
    }
    if (required?.typeDocuments) {
        const documentsType = await getDocumentsTypeForContracts(context)
        context.commit('setOwnersDocumentsType', documentsType)
    }
}

export async function getInitialContentForMemberships(context, required) {

    const content = await Promise.all([
        fetchBenefitInfo(context, {title: true, subscribable: 0}), fetchMemberships(context), fetchTypesMemberships(), fetchPaymentTypes(), fetchBlackouts(context), fetchRetornos(), fetchCategories()
    ])
    const [ benefits, memberships, typeMemberships, paymentTypes, blackouts, retornos, categories] = content

    context.commit('setBenefitTitles', benefits)
    context.commit('setMemberships', memberships)
    context.commit('setTypeMemberships', typeMemberships)
    context.commit('setPaymentTypes', paymentTypes)
    context.commit('setBlackouts', blackouts)
    context.commit('setRetornos', retornos)
    context.commit('setCategories', categories)

    return content
}

export async function getInitialContentForMembershipsEdition(context, required) {
    if (required?.benefits) {
        const benefits = await fetchBenefitInfo(context, {title: true, subscribable: 0})
        context.commit('setBenefitTitles', benefits)
    }
    if (required?.paymentTypes) {
        const paymentTypes = await fetchPaymentTypes()
        context.commit('setPaymentTypes', paymentTypes)
    }
    if (required?.blackouts) {
        const blackouts = await fetchBlackouts(context)
        context.commit('setBlackouts', blackouts)
    }
    if (required?.retornos) {
        const retornos = await fetchRetornos()
        context.commit('setRetornos', retornos)
    }
}

export async function getInitialContentForSubscriptions(context, required) {
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }

    if (required?.hotels) {
        const hotels = await fetchHotels(payloadHotels)
        context.commit('start/setHotels', hotels, { root: true }) //muto state hotels en start
    }
    if (required?.memberships) {
        const memberships = await fetchMembershipsInfo(context, {typeReq: 'title'})
        context.commit('setMembershipsTitles', memberships)
    }

    return true
}

export async function getPaymentsAndReturnTypes(context) {
    const content = await Promise.all([
        fetchPaymentTypes(), fetchRetornos()
    ])
    const [paymentTypes, retornos] = content

    context.commit('setPaymentTypes', paymentTypes)
    context.commit('setRetornos', retornos)
    return content
}

export async function fetchPaymentMethods() {
    const isOwner = { isOwner: 'True' }
    try {
        const response = await Vue.axios({
            url: `/catalogs/paymentmethod/`,
            params: isOwner
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchPaymentMethods end')
    }
}

export async function fetchPaymentMethodsFeeQuota() {
    const isOwner = { isOwner: 1 }
    try {
        const response = await Vue.axios({
            url: `/catalogs/paymentmethod/`,
            params: isOwner
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchPaymentMethodsFeeQuota end')
    }
}

export async function fetchCondoFeeDetail(context, idCondo) {
    
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getCondoFeeDetail/`,
            params: {idCondo}
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchCondoFeeDetail end')
    }
}


// Crud BLackouts
export async function fetchBlackouts(context) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/blackout/`,            
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status  ? data : []
  
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchBlackouts end')
    }
}

export async function fetchBlackoutsInfo(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/blackout/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta

        if (payload.typeReq == 'title') {
            context.commit('setBlackoutsTitles', status ? data : [])
            return status
        }

        else return status ? data : false
  
    } catch (error) {
        const {status, message} = error.response && error.response.data
        console.log(error, message)
        if (message) showAlertMessage("Error", "InfoIcon", `Ha ocurrido un error al solicitar el registro, intentelo más tarde.`, "danger", 4000, "bottom-right")
        return status || false
    } finally {
        console.log('fetchBlackoutsInfo end')
    }
}

export async function fetchCancellationReasons() {
    try {
        const response = await Vue.axios({            
            url: '/catalogs/cancelMotiveList/',
            params: {
                idCanal: 29
            }
        })
        const respuesta = response && response.data
        // console.log(respuesta)
        // const { status, data } = respuesta        
        return respuesta
  
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchCancellationReasons end')
    }
}


export async function saveBlackout(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/blackout/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        return {status: false, message: error}
    } finally {
        console.log('saveBlackout end')
    }
}

export async function updateBlackout(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/blackout/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        return {status: false, message: error}
    } finally {
        console.log('updateBlackout end')
    }
}
// Crud BLackouts Contracts
export async function fetchBlackoutsContracts(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/blackoutContracts/`,
            params: {
                idContract: payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status  ? data : []
  
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchBlackoutsContracts end')
    }
}

export async function saveBlackoutContracts(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/blackoutContracts/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error, message)
        const {status, message} = error.response && error.response.data
        if (message) showAlertMessage("Error", "InfoIcon", `Ha ocurrido un error al guardar el registro, intentelo más tarde.`, "danger", 4000, "bottom-right")
        return status || false
    } finally {
        console.log('saveBlackout end')
    }
}

export async function updateBlackoutContracts(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/blackoutContracts/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        const {status, message} = error.response && error.response.data
        if (message) showAlertMessage("Error", "InfoIcon", `Ha ocurrido un error al actualizar el registro: ${message} intentelo más tarde.`, "danger", 4000, "bottom-right")
        return status || false
    } finally {
        console.log('updateBlackout end')
    }
}

export async function deleteBlackoutContracts(context, id) {
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/blackoutContracts/`,
            data: {
                id: id
            }
        })
        const respuesta = response && response.data
        const {status, message} = respuesta
        showAlertMessage("Hecho", "InfoIcon", message, "success", 4000, "bottom-right")
        return status

    } catch (error) {
        const {status, message} = error.response && error.response.data
        console.log(error, message)
        showAlertMessage("Error", "InfoIcon", `Ha ocurrido un error al eliminar el registro: ${message} intentelo más tarde.`, "danger", 4000, "bottom-right")
        return status
    } finally {
        console.log('deleteBlackoutContracts end')
    }
}

// Crud TypesMemberships
export async function fetchTypesMemberships() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/typeMemberships/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status  ? data : []
  
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchTypesMemberships end')
    }
}

export async function saveTypeMembership(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/typeMemberships/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('saveTypeMembership end')
    }
}

export async function updateTypeMembership(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/typeMemberships/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('updateTypeMembership end')
    }
}

// Crud typesBenefits
export async function fetchTypesBenefits() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/typesBenefits/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return addIsLoadingToArray( status  ? data : [] )
        
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchTypesBenefits end')
    }
}

export async function saveTypeBenefit(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/typesBenefits/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('saveTypeBenefit end')
    }
}

export async function updateTypeBenefit(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/typesBenefits/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('updateTypeBenefit end')
    }
}

// Crud GroupsTypesBenefits
export async function fetchGroupsTypesBenefits() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/groupsTypesBenefits/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return addIsLoadingToArray( status  ? data : [])
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchGroupsTypesBenefits end')
    }
}

export async function saveGroupTypesBenefit(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/groupsTypesBenefits/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('saveTypeMembership end')
    }
}

export async function updateGroupTypeBenefit(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/groupsTypesBenefits/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('updateGroupTypeBenefit end')
    }
}


//crud Benefits

export async function fetchBenefits(context) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/benefits/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        context.commit('setBenefits',status  ? data : [])
        return addIsLoadingToArray( status  ? data : [])
    } catch (error) {
        console.log(error)
        context.commit('setBenefits', [])
        return []
    } finally {
        console.log('fetchBenefits end')
    }
}
export async function fetchBenefitsProducts(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/benefitsProducts/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
        showAlertMessage("Error", "InfoIcon", `Ha ocurrido un error al buscar datos, por favor intentelo más tarde.`, "danger", 4000, "bottom-right")
        return []
    } finally {
        console.log('fetchBenefitsProducts end')
    }
}

export async function fetchBenefitsProductsShop(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/benefitsProductsShop/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchBenefitsProducts end')
    }
}

export async function saveBenefit(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/benefits/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        return {status: false, message: error}
    } finally {
        console.log('saveBenefit end')
    }
}

export async function updateBenefit(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/benefits/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        return {status: false, message: error}
    } finally {
        console.log('updateBenefit end')
    }
}

//Crud memberships

export async function fetchMemberships(context) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/membership/`,
        })
        const respuesta = response && response.data        
        const { status, data } = respuesta        
        return addIsLoadingToArray( status  ? data : [])
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchMemberships end')
    }
}

export async function fetchMembershipsInfo(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/membership/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        return respuesta.status ? respuesta.data : []

    } catch (error) {
        const errors = error?.response && error?.response?.data
        console.log(error, errors?.message || null)
        if (errors?.message) showAlertMessage("Error", "InfoIcon", `Ha ocurrido un error al solicitar el registro, intentelo más tarde.`, "danger", 4000, "bottom-right")
        return []
    } finally {
        console.log('fetchMembershipsInfo end')
    }
}

export async function fetchBenefitInfo(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/benefits/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        return respuesta.status ? respuesta.data : []

    } catch (error) {
        const errors = error?.response && error?.response?.data
        console.log(error, errors?.message || null)
        if (errors?.message) showAlertMessage("Error", "InfoIcon", `Ha ocurrido un error al solicitar el registro, intentelo más tarde.`, "danger", 4000, "bottom-right")
        return []
    } finally {
        console.log('fetchBenefitInfo end')
    }
}

export async function saveMembership(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/membership/`,
            data: payload

        })
        const respuesta = response && response.data        
        return respuesta
    } catch (error) {
        console.log(error)
        return {status: false, message: error}
    } finally {
        console.log('saveMembership end')
    }
}

export async function updateMembership(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/membership/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        return {status: false, message: error}
    } finally {
        console.log('updateMembership end')
    }
}
//Crud Cuttoffs

export async function fetchCutoffs() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/cutoff/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchCutoffs end')
    }
}

// Crud Housing
export async function fetchHousing(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/housing/?`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        const respuesta = error?.response && error?.response?.data
        if (respuesta?.message) showAlertMessage('Error', 'BellIcon', `${respuesta.message}`, 'warning', 4000, 'bottom-right')
        console.log(error)
        return []
    } finally {
        console.log('fetchHousing end')
    }
}

export async function fetchHousingReport(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/housingReport/`,
            responseType:'blob',
            data: payload
        })
        const respuesta = response && response.data
        const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", 'housingReport.xlsx')
        document.body.appendChild(fileLink)
        fileLink.click()
        return true
    } catch (error) {
        if( error.response && error.response.status === 500 ){
            return showAlertMessage("Error", "InfoIcon", `Ha ocurrido un error al generar el excel, intentelo más tarde`, "danger", 4000, "bottom-right")
        }
        return showAlertMessage("Error", "InfoIcon", `Ocurrió un error en la petición.`, "danger", 4000, "bottom-right")

    } finally {
        console.log('fetchHousingReport end')
    }
}

export async function saveCondoFeeCalculation(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getCondoFeeCalculation/`,
            data: { ...payload }
        })
        const respuesta = response && response.data        
        return respuesta
    } catch (error) {
       
        return error?.response && error?.response?.data     
    } finally {
        console.log('saveCondoFeeCalculation end')
    }
}



export async function fetchBenefitsByContract(context, id) {

    try {
        const response = await Vue.axios({
            url: `/fivesClub/getBenefits/?idContract=${id}`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status ? data : []
    } catch (error) {

        console.log(error)
    } finally {
        console.log('fetchBenefitsByContract end')
    }
}

export async function fetchBenefitsByYearContract(context, payload) {    
    const { idContract, year,typeBenefit } = payload
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getBenefitsYear/`,
            params: { idContract, year,typeBenefit }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta               
        return status  ? data : []
  
    } catch (error) {
        console.log(error)
        showAlertMessage("Error", "InfoIcon", `Ha ocurrido un error al buscar, intentelo más tarde`, "danger", 4000, "bottom-right")
        return {status: false}
    } finally {
        console.log('fetchBenefitsByYearContract end')
    }
}


// Crud condos
export async function fetchCondos() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/condos/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        const condos = formatAddressCondos(status ? data : [])
        return addIsLoadingToArray(condos)
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchCondos end')
    }
}

export async function fetchCondosById(context, data) {
    const { idCondo }= data
    try {
        const response = await Vue.axios({
            url: `/fivesClub/condos/`,
            params: { idCondo }

        })
        const respuesta = response && response.data
        const { status, data } = respuesta                  
        return status ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchCondosById end')
    }
}



// Crud SubCondos
export async function fetchSubCondos() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/subCondos/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchSubCondos end')
    }
}

export async function fetchSubCondoById(context, data) {
    const { idSubCondo }= data
    try {
        const response = await Vue.axios({
            url: `/fivesClub/subCondos/`,
            params: { idSubCondo }

        })
        const respuesta = response && response.data
        const { status, data } = respuesta                  
        return status ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchSubCondoById end')
    }
}


// Crud Season
export async function fetchSeasons() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/season/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchSeasons end')
    }
}

// Crud Cutoff
export async function fetchCutoff() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/cutoff/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchCutoff end')
    }
}


export async function fetchHousingCondominalFees(context, id) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/condominalfeehousing/`,
            params: {
                IdHousing:id
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchHousingCondominalFees end')
    }
}

export async function fetchAnnualsCondominalsBudgets(context, id) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/condominalfee/`,
            params: {
                IdCondo:id
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchAnnualsCondominalsBudgets end')
    }
}

export async function fetchCondominalBudgetByPeriods(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/condominalfeeperiod/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchCondominalBudgetByPeriods end')
    }
}

export async function calcCondoFeeHousing(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/recalculateCondoFeeHousing/`,
            data: { ...payload }
        })
        const respuesta = response && response.data        
        return respuesta
    } catch (error) {
        
        if( error.response && error.response.status === 502 ){
            return { status: false, message: 'Error 502: Bad Gateway'}
        }
        return { status: false, message: 'Ocurrió un error en la petición.'} 

    } finally {
        console.log('calcCondoFeeHousing end')
    }
}

export async function fetchCondominalBudgetFeesByYear(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getHousingAnualFee/`,
            params: { ...payload }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchCondominalBudgetFeesByYear end')
    }
}

export async function fetchCondominalBudgetFeesByYearReport(context, payload) {
    try {

        const response = await Vue.axios({
            url: `/fivesClub/getHousingAnualFee/?`,
            responseType:'blob',
            params: { ...payload }
        })
        const respuesta = response && response.data
        const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", 'condominalFeesReportHousing.xlsx')
        document.body.appendChild(fileLink)
        fileLink.click()
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchCondominalBudgetFeesByYearReport end')
    }
}

export async function fetchCondominalFeesDetailsReport(context, payload) {
    try {

        const response = await Vue.axios({
            url: `/fivesClub/getOwnerCondoFeeHousingDetailReport/?`,
            responseType:'blob',
            params: { ...payload }
        })
        const respuesta = response && response.data
        const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", 'reporte-detalle-cuotas-vivienda.xlsx')
        document.body.appendChild(fileLink)
        fileLink.click()
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchCondominalFeesDetailsReport end')
    }
}

export async function fetchFeeHousingDetail(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getOwnerCondoFeeHousingDetail/`,
            params: { ...payload }
        })
        const respuesta = response && response.data        
        return respuesta
    } catch (error) {
        if( error.response && error.response.status === 500 ){
            return { status: false, message: 'Error 500: Internal Server Error'}
        }
        return { status: false, message: 'Ocurrió un error en la petición.'} 
    } finally {
        console.log('fetchFeeHousingDetail end')
    }
}


export async function fetchDetailBudgetPeriod(context, idPeriod) {
    
    try {
        const response = await Vue.axios({
            url: `/fivesClub/condominalfee/`,
            params: {
                idPeriod
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchDetailBudgetPeriod end')
    }
}

export async function fetchCondominalFeesReport(context, payload) {
    try {
        const {id, name} = payload

        const response = await Vue.axios({
            url: `/fivesClub/condominalfee/?`,
            responseType:'blob',
            params: { ...payload }
        })
        const respuesta = response && response.data
        const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", 'condominalFeesReport-'+name+'.xlsx')
        document.body.appendChild(fileLink)
        fileLink.click()
        return true
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchCondominalFeesReport end')
    }
}

export async function deleteCondominalFee(context, payload) {
    const { id } = payload
   
    try {
        
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/condominalfee/`,            
            data: payload,
        })
        const respuesta = response && response.data        
        return respuesta

    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('deleteCondominalFee end')
    }
}

export async function saveHousing(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/housing/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('saveHousing end')
    }
}

export async function saveSubCondos(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/subCondos/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('saveSubCondos end')
    }
}

export async function saveCondos(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/condos/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('saveCondos end')
    }
}

export async function saveSeason(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/season/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('saveSeason end')
    }
}
export async function saveCutoff(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/cutoff/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('saveCutoff end')
    }
}

export async function updateCondos(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/condos/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('updateCondos end')
    }
}

export async function updateSubCondo(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/subCondos/`,
            data: payload
        })
        const respuesta = response && response.data        
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('updateSubCondo end')
    }
}

export async function updateHousing(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/housing/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('updateSubCondo end')
    }
}


export async function fetchCondoFeeHousingDetail(context, idHousingFee) {
    try {
        const response = await Vue.axios({            
            url: `/fivesClub/getCondoFeeHousingDetail/`,
            params: {idHousingFee}
        })
        const respuesta = response && response.data        
        return respuesta
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchCondoFeeHousingDetail end')
    }
}

export async function updateCondominalFee(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/condominalfee/`,
            data: payload
        })
        const respuesta = response && response.data        
        return respuesta
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('updateCondominalFee end')
    }
}

export async function saveCondominalFee(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/condominalfee/`,
            data: payload
        })
        const respuesta = response && response.data        
        return respuesta

    } catch (error) {        
        return error.response && error.response.data        
    } finally {
        console.log('saveCondominalFee end')
    }
}

export async function updateSeason(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/season/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('updateSeason end')
    }
}


export async function updateCutoff(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/cutoff/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('updateCutoff end')
    }
}


export async function fetchPaymentTypes() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getPaymentsType/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchPaymentTypes end')
    }
}

export async function fetchRetornos() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getReturns/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchRetornos end')
    }
}


export async function fetchGetRentalPool(context) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getRentalPool/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta       
        return status  ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchGetRentalPool end')
    }
}

export async function fetchRentalPoolById(context, data) {
    const { idRentalPool }= data

    try {
        const response = await Vue.axios({
            url: `/fivesClub/getRentalPool/`,
            params: { idRentalPool }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta       
        return status  ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchRentalPoolById end')
    }
}


export async function saveRentalPool(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getRentalPool/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('saveRentalPool end')
    }
}


export async function updateRentalPool(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getRentalPool/`,
            data: payload
        })
        const respuesta = response && response.data        
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('updateRentalPool end')
    }
}


export async function deleteHousingInRentalPool(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/getRentalPool/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('deleteHousingInRentalPool end')
    }
}


//crud propietarios

export async function fetchOwners(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getOwners/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta              
        return status ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchOwners end')
    }
}

export async function fetchOwnersHousingData(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getOwnersHousingData/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchOwnersHousingData end')
    }
}

export async function changePasswordOwner(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/changepassword/`,
            data: payload

        })
        const respuesta = response && response.data
        return respuesta               
    } catch (error) {
        console.log(error)
    } finally {
        console.log('changePasswordOwner end')
    }
}

export async function fetchNacionality() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getNacionality/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status  ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchNacionality end')
    }
}

export async function saveOwners(context, payload) {
    // const { photo } = payload
    try {
        const formData = new FormData()
        // formData.append('file', photo)
        formData.append('data', JSON.stringify(payload))

        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getOwners/`,
            data: formData
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {        
        return error
    } finally {
        console.log('saveOwners end')
    }
}

export async function updateOwners(context, payload) {
    // const { photo } = payload    
    try {
        const formData = new FormData()
        // formData.append('file', photo)
        formData.append('data', JSON.stringify(payload))

        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getOwners/`,
            data: formData
        })
        const respuesta = response && response.data
        return respuesta?.status
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('updateOwners end')
    }
}

export async function updateStatusOwner(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/updateStatusOwners/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('updateStatusOwner end')
    }
}


export async function checkIfClientExists(context, email) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getOwners/?email=${email}`,
        })
        const clientData = response && response.data

        return clientData
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('checkIfClientExists end')
    }
}

export async function fetchHousingOptions(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getHousingOptions/?`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        const respuesta = error?.response && error?.response?.data
        if (respuesta?.message) showAlertMessage('Error', 'BellIcon', `${respuesta.message}`, 'warning', 4000, 'bottom-right')
        console.log(error)
        return []
    } finally {
        console.log('fetchHousingOptions end')
    }
}


export async function deleteInMembership(context, payload) {
    // console.log(payload)
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/membership/`,
            data: payload
        })
        const respuesta = response && response.data        
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('deleteInMembership end')
    }
}

export async function deleteHouseOwner(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/getOwners/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        const respuesta = error?.response?.data || false
        return respuesta
    } finally {
        console.log('deleteHouseOwner end')
    }
}

export async function fetchGetHousing(context, dataInfo) { //por borrar

    try {
        const response = await Vue.axios({
            url: `/fivesClub/getHousingData/`,
            params: { ...dataInfo }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchGetHousing end')
    }
}


export async function getHousingOwners(context, payload) {
    try {

        const response = await Vue.axios({
            url: `/fivesClub/getHousingOwners/`,
            params: { ...payload }
        })

        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        return {status: false, data: [], message: 'Ha ocurrido un error, por favor inténtelo más tarde'}
    } finally {
        console.log('La petición para solicitar los propietarios ha terminado')
    }
}

// uno
export async function getContractOwner(context, payload) {

    try {
        const response = await Vue.axios({
            url: `/fivesClub/getContractOwner/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('getContractOwner end')
    }
}

export async function getContractUser(context, id) {

    try {

        const response = await Vue.axios({
            url: `/fivesClub/getContractUser/?idOwner=${id}`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status  ? data : []
  
    } catch (error) {

        console.log(error)
    } finally {
        console.log('getContractUser end')
    }
}


export async function getdiscountsContract(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getdiscountsContract/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status  ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('fivesClubCatalogs/getdiscountsContract', 'La petición para obtener los beneficios y descuentos de membresía ha terminado')
    }
}

export async function getDescountMemberships(context, id) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/descountMemberships/?membership=${id}`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status  ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('getDescountMemberships end')
    }
}


export async function fetchCategories() {
 
    try {
        
        const response = await Vue.axios({
            url: '/catalogs/category/',
      
        })
        const clientData = response && response.data

        return clientData

    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchCategories end')
    }
}

export async function saveCategoriesMembership(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/descountMemberships/`,
            data: payload

        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('saveCategoriesMembership end')
    }
}


// NewLetter
export async function fetchNewsLetters() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getNewsLetters/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta       
        return status  ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchNewsLetters end')
    }
}

export async function fetchNewsLetterById(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getNewsLetters/?idNewsletter=${payload.idNewsletter}`,
            
        })
        const respuesta = response && response.data
       
        const { status, data } = respuesta       
        return status  ? data : null
    } catch (error) {
        console.log(error)
        return null
    } finally {
        console.log('fetchNewsLetterById end')
    }
}

export async function saveNewsLetters(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getNewsLetters/`,
            data: payload

        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('saveNewsLetters end')
    }
}

export async function updateNewsLetters(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getNewsLetters/`,
            data: payload

        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('updateNewsLetters end')
    }
}

// Hotel Performance
export async function fetchHotelPerformance() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getHotelPerformance/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta       
        return status  ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchHotelPerformance end')
    }
}

export async function fetchHotelPerformanceById(context, idHotelPerformance) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getHotelPerformance/`,
            params: {
                idHotelPerformance
            }
        })
        const respuesta = response && response.data
       
        const { status, data } = respuesta       
        return status  ? data : null
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchHotelPerformanceById end')
    }
}

export async function updateHotelPerformance(context,datos) {
    const { file,id } = datos
    try {
        const formData = new FormData()
        formData.append('id',id)
        formData.append('file', file)
        formData.append('data', JSON.stringify(datos))
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getHotelPerformance/`,
            data: formData
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        const respuesta = error?.response && error?.response?.data
        return respuesta || { status: false, message: 'Ha ocurrido un error, por favor intentelo más tarde o contacte a soporte' }
    } finally {
        console.log('updateHotelPerformance end')
    }
}

export async function saveHotelPerformance(context,datos) {
    const { file,id } = datos
    try {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('data', JSON.stringify(datos))
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getHotelPerformance/`,
            data: formData
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        const respuesta = error?.response && error?.response?.data
        return respuesta || { status: false, message: 'Ha ocurrido un error, por favor intentelo más tarde o contacte a soporte' }
    } finally {
        console.log('saveHotelPerformance end')
    }
}

// Owner Promos
export async function fetchOwnerPromos(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getOwnerPromos/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status  ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchOwnerPromos end')
    }
}

export async function saveOwnerPromos(context,datos) {
    const { imagePromo } = datos
    try {
        const formData = new FormData()
        formData.append('file', imagePromo)
        formData.append('data', JSON.stringify(datos))
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getOwnerPromos/`,
            data: formData
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('saveOwnerPromos end')
    }
}

export async function updateOwnerPromos(context,datos) {
    const { imagePromo } = datos
    try {
        const formData = new FormData()
        formData.append('file', imagePromo)
        formData.append('data', JSON.stringify(datos))
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getOwnerPromos/`,
            data: formData
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('updateOwnerPromos end')
    }
}

export async function deleteOwnerPromos(context, id) {
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/getOwnerPromos/`,
            data: {
                id: id
            }
        })
        const respuesta = response && response.data
        const {status, message} = respuesta
        showAlertMessage("Hecho", "InfoIcon", message, "success", 4000, "bottom-right")
        return status

    } catch (error) {
        const {status, message} = error.response && error.response.data
        console.log(error, message)
        showAlertMessage("Error", "InfoIcon", `Ha ocurrido un error al eliminar el registro: ${message} intentelo más tarde.`, "danger", 4000, "bottom-right")
        return status
    } finally {
        console.log('deleteBlackoutContracts end')
    }
}

// Staff Contact
export async function fetchStaffContact() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getStaffContact/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchStaffContact end')
    }
}

export async function fetchStaffContactById(context, idContact) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getStaffContact/`,
            params: {
                idContact
            }
        })
        const respuesta = response && response.data

        const { status, data } = respuesta
        return status  ? data : null
    } catch (error) {
        console.log(error)
        return null
    } finally {
        console.log('fetchStaffContactById end')
    }
}

export async function updateStaffContact(context,payload) {
    try {
        const formData = new FormData()
        formData.append('file', payload.profilepicture)
        formData.append('data', JSON.stringify(payload))
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getStaffContact/`,
            data: formData
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        const respuesta = error?.response && error?.response?.data
        return respuesta || { status: false, message: 'Ha ocurrido un error, por favor intentelo más tarde o contacte a soporte' }
    } finally {
        console.log('updateStaffContact end')
    }
}

export async function saveStaffContact(context,payload) {
    try {
        const formData = new FormData()
        formData.append('file', payload.profilepicture)
        formData.append('data', JSON.stringify(payload))
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getStaffContact/`,
            data: formData
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        const respuesta = error?.response && error?.response?.data
        return respuesta || { status: false, message: 'Ha ocurrido un error, por favor intentelo más tarde o contacte a soporte' }
    } finally {
        console.log('saveStaffContact end')
    }
}

export async function updateStaffOrder(context,payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/updateStaffOrder/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        const respuesta = error?.response && error?.response?.data
        return respuesta || { status: false, message: 'Ha ocurrido un error, por favor intentelo más tarde o contacte a soporte' }
    } finally {
        console.log('updateStaffOrder end')
    }
}

export async function deleteStaffOrder(context,payload) {
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/getStaffContact/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        const respuesta = error?.response && error?.response?.data
        return respuesta || { status: false, message: 'Ha ocurrido un error, por favor intentelo más tarde o contacte a soporte' }
    } finally {
        console.log('deleteStaffOrder end')
    }
}

// Owner Transactions
export const fetchOwnerTransactionsAcceptance = async (context, payload) => {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getOwnerTransactionsAgreements/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return data
    } catch (error) {
        console.warn(error?.response?.data) //false cuando es un bad request, el message error error.response.data
        return false
    } finally {
        console.log('fetchOwnerTransactionsAcceptance end')
    }
}

export async function fetchOwnerTransactionsAcceptanceExcel(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getOwnerTransactionsAgreements/`,
            responseType:'blob',
            params: {
                ...payload
            }
        })

        const respuesta = response && response.data
        const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", 'Transactions-Agreement-report-'+moment().format("YYYY-MM-DD_HH.mm")+'.xlsx')
        document.body.appendChild(fileLink)
        fileLink.click()
        return true

    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchMaintenanceRequestsExcel end')
    }
}

export const fetchOwnerTransactionsAcceptanceRelated = async (context, payload) => {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getOwnerTransactionsAgreementsRelated/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return data
    } catch (error) {
        console.warn(error?.response?.data) //false cuando es un bad request, el message error error.response.data
        return false
    } finally {
        console.log('fetchOwnerTransactionsAcceptanceRelated end')
    }
}

export const setOwnerTransactionsAcceptanceResponse = async (context, payload) => {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getOwnerTransactionsAgreements/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.warn(error?.response?.data) //false cuando es un bad request, el message error error.response.data
        return false
    } finally {
        console.log('setOwnerTransactionsAcceptanceResponse end')
    }
}


export async function fetchSocialNetworks() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getSocialNetworks/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status  ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchOwnerPromos end')
    }
}

// Maintenance Request
export const fetchMaintenanceRequests = async (context, payload) => {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getMaintenanceRequests/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status  ? data : []
    } catch (error) {
        console.warn(error?.response?.data) //false cuando es un bad request, el message error error.response.data
        return []
    } finally {
        console.log('fetchMaintenanceRequests end')
    }
}

export async function fetchMaintenanceRequestsExcel(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getMaintenanceRequests/`,
            responseType:'blob',
            params: {
                ...payload
            }
        })

        const respuesta = response && response.data
        const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", 'MaintenanceRequests.xlsx')
        document.body.appendChild(fileLink)
        fileLink.click()
        return true

    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchMaintenanceRequestsExcel end')
    }
}

export async function setMaintenanceResolution(context,datos) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/setMaintenanceRequestResolution/`,
            data: datos
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('setMaintenanceResolution end')
    }
}


// Owner Bookings
export async function fetchOwnerBooking(context) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getOwnerBooking/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        const result = status ? data : []
        context.commit('setOwnersBookings', result)
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchOwnerBooking end')
    }
}

export async function updateOwnerBooking(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getOwnerBooking/`,
            data: payload

        })
        const respuesta = response && response.data
        // const { status, data } = respuesta       
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('updateOwnerBooking end')
    }
}

export async function fetchOwnerBookingId(context,id) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getOwnerBooking/?id=${id}`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta       
        return status  ? data : []
    } catch (error) {
        console.log(error)
        showAlertMessage("Error", "InfoIcon", `Ha ocurrido un error al solicitar este registro, intentelo más tarde.`, "danger", 4000, "bottom-right")
        return false
    } finally {
        console.log('fetchOwnerBookingId end')
    }
}

export async function fetchOwnerBookingFilter(context, dataInfo) {  
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getOwnerBooking/?`,
            params: {
                ...dataInfo
            }
        })
        const respuesta = response && response.data
       
        const { status, data } = respuesta   
        return status ? data : []
    } catch (error) {
        console.log(error)
        showAlertMessage("Error", "InfoIcon", `Ha ocurrido un error al solicitar las reservas, intentelo más tarde.`, "danger", 4000, "bottom-right")
        return []
    } finally {
        console.log('fetchOwnerBookingFilter end')
    }
}

export async function saveOwnerBooking(context, dataInfo) {
  
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getOwnerBooking/?`,
            data:dataInfo
            
        })
        const respuesta = response && response.data
       
        const { status, data } = respuesta    
        return status ? data : []                  
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('saveOwnerBooking end')
    }
}

export async function getOwnerBookingBlack(context,dataInfo) {
    try {
     
        const response = await Vue.axios({
            url: `/fivesClub/getOwnerBookingBlack/`,
            params: { ...dataInfo }
        })
        const respuesta = response && response.data               
        return respuesta 
    } catch (error) {
        console.log(error)
    } finally {
        console.log('getOwnerBookingBlack end')
    }
}

export async function fetchtOwnerBookingId(context,id) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getOwnerBooking/?id=${id}`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta       
        return status  ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchtOwnerBookingId end')
    }
}

export async function sendAuthorization(context, dataInfo) {  
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/authOwnerBooking/?`,
                data:dataInfo
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        const respuesta = error.response && error.response.data
        let msg = respuesta?.message || `Ha ocurrido un error al autorizar la reserva, intentelo más tarde o contacte a desarrollo.`
        showAlertMessage( 'Error: No se pudo autorizar la reserva', 'InfoIcon', msg, 'danger', 4000, 'bottom-right' )
        return false
    } finally {
        console.log('sendAuthorization end')
    }
}

export async function  cancelOwnerBooking(context, dataInfo) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/cancelOwnerBooking/?`,
                data:dataInfo
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        showAlertMessage("Error", "InfoIcon", `Ha ocurrido un error al cancelar la reserva, intentelo más tarde o contacte a desarrollo.`, "danger", 4000, "bottom-right")
        const response = error.response && error.response.data
        console.warn(response?.message || response)
        return false
    } finally {
        console.log('cancelOwnerBooking end')
    }
}

export async function fetchCancelOwnerBookingInfo(context, id) {

    try {
        const response = await Vue.axios({
            url: `/fivesClub/cancelOwnerBooking/?`,
            params:{
                id:id
            }
        })
        const {status, data} = response && response.data
        if (status) return data
        return status
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchCancelOwnerBookingInfo end')
    }
}

export async function fetchBookingsPenalities(context, bookingId) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getOwnerBookingPenality/?`,
            params:{
                idBooking: bookingId
            }
        })
        const {status, penality} = response && response.data

        return status ? penality : status
    } catch (error) {
        console.log(error?.response?.data)
        return false
    } finally {
        consoleMsgFinally('fetchBookingsPenalities end')
    }
}

export async function fetchBookingPreviousData(context, id) {

    try {
        const response = await Vue.axios({
            url: `/fivesClub/getPreviousBooking/?`,
            params:{
                id:id
            }
        })
        const {status, data} = response && response.data
        if (status && typeof data != 'array') return data
        else return false
    } catch (error) {
        console.log(error)
        showAlertMessage("Error", "InfoIcon", `Ha ocurrido un error, intentelo más tarde o contacte a desarrollo.`, "danger", 4000, "bottom-right")
        const response = error.response && error.response.data
        console.warn(response?.message || response)
        return false
    } finally {
        console.log('fetchCancelOwnerBookingInfo end')
    }
}

export async function sendSpecialNotes(context, dataInfo) {
  
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/SpecialNotes/?`,
            data:dataInfo
        })
        const respuesta = response && response.data
        const { status, message } = respuesta

        if (status) showAlertMessage( "Ok: Status actualizado", "InfoIcon", message, "success", 4000, "bottom-right")
        else showAlertMessage("Status no actualizado", "InfoIcon", message, "warning", 4000, "bottom-right")

        return status

    } catch (error) {
        console.log(error)
        showAlertMessage("Error: Status no actualizado", "InfoIcon", `Ha ocurrido un error al actualizar el status, intentelo más tarde`, "danger", 4000, "bottom-right")
        return {status: false}
    } finally {
        console.log('sendSpecialNotes end')
    }
}

export async function fetchExcelOwnersBookings(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getOwnerBooking/?`,
            responseType:'blob',
            params: { ...payload }
        })
        const respuesta = response && response.data
        const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", 'ownerBookings-report.xlsx')
        document.body.appendChild(fileLink)
        fileLink.click()

    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchExcelOwnersBookings end')
    }
}

export async function sendChangeFolioReserve(context, dataInfo) {
  
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/changeFolioReserve/?`,
            data:dataInfo
        })
        const respuesta = response && response.data
        
        const { status, message } = respuesta

        if (status) showAlertMessage( "Ok: Status actualizado", "InfoIcon", message, "success", 4000, "bottom-right")
        else showAlertMessage("Status no actualizado", "InfoIcon", message, "warning", 4000, "bottom-right")

        return respuesta

    } catch (error) {
        console.log(error)
        showAlertMessage("Error: Status no actualizado", "InfoIcon", `Ha ocurrido un error al actualizar el status, intentelo más tarde`, "danger", 4000, "bottom-right")
        return {status: false}
    } finally {
        console.log('SendChangeFolioReserve end')
    }
}

export async function fetchInfoTransfer(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getTransferPrearrival/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return data
    } catch (error) {
        const respuesta = error?.response && error?.response?.data
        if (respuesta?.message) showAlertMessage('Error', 'BellIcon', `${respuesta.message}`, 'warning', 4000, 'bottom-right')
        console.log(error)
        return false
    } finally {
        console.log('fetchInfoTransfer end')
    }
}

export async function saveInfoTransfer(context, payload) {
  
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getTransferPrearrival/?`,
            data:payload
            
        })
        const respuesta = response && response.data
       
        const { status, data } = respuesta    
        return status ? data : []                  
    } catch (error) {
        showAlertMessage("No se creó el registro de transportación", "InfoIcon", `Ha ocurrido un error al crear el registro, intentelo más tarde`, "danger", 4000, "bottom-right")        
        console.log(error)
        return false
    } finally {
        console.log('saveInfoTransfer end')
    }
}

export async function updateInfoTransfer(context, payload) {
  
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getTransferPrearrival/?`,
            data:payload
            
        })
        const respuesta = response && response.data
       
        const { status, data } = respuesta    
        return status ? data : []                  
    } catch (error) {
        showAlertMessage("No se actualizó el registro de transportación", "InfoIcon", `Ha ocurrido un error al actualizar el registro, intentelo más tarde`, "danger", 4000, "bottom-right")
        console.log(error)
        return false
    } finally {
        console.log('updateInfoTransfer end')
    }
}

export async function deleteInfoTransfer(context, payload) {
  
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/getTransferPrearrival/?`,
            data:payload
            
        })
        const respuesta = response && response.data
        const { status, data } = respuesta    
        return status ? data : []                  
    } catch (error) {
        showAlertMessage("No se actualizó el registro de transportación", "InfoIcon", `Ha ocurrido un error al actualizar el registro, intentelo más tarde`, "danger", 4000, "bottom-right")
        console.log(error)
        return false
    } finally {
        console.log('deleteInfoTransfer end')
    }
}

export async function fetchInfoMeal(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getMealPrearrival/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return data
    } catch (error) {
        const respuesta = error?.response && error?.response?.data
        if (respuesta?.message) showAlertMessage('Error', 'BellIcon', `${respuesta.message}`, 'warning', 4000, 'bottom-right')
        console.log(error)
        return false
    } finally {
        console.log('fetchInfoMeal end')
    }
}

export async function saveInfoMeal(context, payload) {
  
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getMealPrearrival/?`,
            data:payload
            
        })
        const respuesta = response && response.data
       
        const { status, data } = respuesta    
        return status ? data : []                  
    } catch (error) {
        showAlertMessage("No se creó el registro de plan de alimentos", "InfoIcon", `Ha ocurrido un error al crear el registro, intentelo más tarde`, "danger", 4000, "bottom-right")
        console.log(error)
        return false
    } finally {
        console.log('saveInfoMeal end')
    }
}

export async function updateInfoMeal(context, payload) {
  
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getMealPrearrival/?`,
            data:payload
            
        })
        const respuesta = response && response.data
       
        const { status, data } = respuesta    
        return status ? data : []                  
    } catch (error) {
        showAlertMessage("No se actualizó el registro de plan de alimentos", "InfoIcon", `Ha ocurrido un error al actualizar el registro, intentelo más tarde`, "danger", 4000, "bottom-right")
        console.log(error)
        return false
    } finally {
        console.log('updateInfoMeal end')
    }
}

export async function deleteInfoMeal(context, payload) {
  
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/getMealPrearrival/?`,
            data:payload
            
        })
        const respuesta = response && response.data
        const { status, data } = respuesta    
        return status ? data : []                  
    } catch (error) {
        showAlertMessage("No se actualizó el registro de plan de alimentos", "InfoIcon", `Ha ocurrido un error al actualizar el registro, intentelo más tarde`, "danger", 4000, "bottom-right")
        console.log(error)
        return false
    } finally {
        console.log('deleteInfoMeal end')
    }
}

export async function fetchInfoPreferences(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getPreferencesPrearrival/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return data
    } catch (error) {
        const respuesta = error?.response && error?.response?.data
        if (respuesta?.message) showAlertMessage('Error', 'BellIcon', `${respuesta.message}`, 'warning', 4000, 'bottom-right')
        console.log(error)
        return false
    } finally {
        console.log('fetchInfoPreferences end')
    }
}

export async function saveInfoPreferences(context, payload) {
  
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getPreferencesPrearrival/?`,
            data:payload
            
        })
        const respuesta = response && response.data
       
        const { status, data } = respuesta    
        return status ? data : []                  
    } catch (error) {
        showAlertMessage("No se creó el registro de preferencias", "InfoIcon", `Ha ocurrido un error al crear el registro, intentelo más tarde`, "danger", 4000, "bottom-right")        
        console.log(error)
        return false
    } finally {
        console.log('saveInfoPreferences end')
    }
}

export async function updateInfoPreferences(context, payload) {
  
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getPreferencesPrearrival/?`,
            data:payload
            
        })
        const respuesta = response && response.data
       
        const { status, data } = respuesta    
        return status ? data : []                  
    } catch (error) {
        showAlertMessage("No se actualizó el registro de preferencias", "InfoIcon", `Ha ocurrido un error al actualizar el registro, intentelo más tarde`, "danger", 4000, "bottom-right")
        console.log(error)
        return false
    } finally {
        console.log('updateInfoPreferences end')
    }
}

export async function deleteInfoPreferences(context, payload) {
  
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/getPreferencesPrearrival/?`,
            data:payload
            
        })
        const respuesta = response && response.data
        const { status, data } = respuesta    
        return status ? data : []                  
    } catch (error) {
        showAlertMessage("No se actualizó el registro de preferencias", "InfoIcon", `Ha ocurrido un error al actualizar el registro, intentelo más tarde`, "danger", 4000, "bottom-right")
        console.log(error)
        return false
    } finally {
        console.log('deleteInfoPreferences end')
    }
}

export async function fetchInfoRestaurants(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getRestaurantsPrearrival/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return data
    } catch (error) {
        const respuesta = error?.response && error?.response?.data
        if (respuesta?.message) showAlertMessage('Error', 'BellIcon', `${respuesta.message}`, 'warning', 4000, 'bottom-right')
        console.log(error)
        return false
    } finally {
        console.log('fetchInfoRestaurants end')
    }
}

export async function saveInfoRestaurants(context, payload) {
  
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getRestaurantsPrearrival/?`,
            data:payload
            
        })
        const respuesta = response && response.data
       
        const { status, data } = respuesta    
        return status ? data : []                  
    } catch (error) {
        showAlertMessage("No se creó el registro de reserva de restaurante", "InfoIcon", `Ha ocurrido un error al crear el registro, intentelo más tarde`, "danger", 4000, "bottom-right")
        console.log(error)
        return false
    } finally {
        console.log('saveInfoRestaurants end')
    }
}

export async function updateInfoRestaurants(context, payload) {
  
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getRestaurantsPrearrival/?`,
            data:payload
            
        })
        const respuesta = response && response.data
       
        const { status, data } = respuesta    
        return status ? data : []                  
    } catch (error) {
        showAlertMessage("No se actualizó el registro de reserva de restaurante", "InfoIcon", `Ha ocurrido un error al actualizar el registro, intentelo más tarde`, "danger", 4000, "bottom-right")
        console.log(error)
        return false
    } finally {
        console.log('updateInfoRestaurants end')
    }
}

export async function deleteInfoRestaurants(context, payload) {
  
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/getRestaurantsPrearrival/?`,
            data:payload
            
        })
        const respuesta = response && response.data
       
        const { status, data } = respuesta    
        return status ? data : []                  
    } catch (error) {
        console.log(error)
        showAlertMessage("No se actualizó el registro de reserva de restaurante", "InfoIcon", `Ha ocurrido un error al actualizar el registro, intentelo más tarde`, "danger", 4000, "bottom-right")        
        return false
    } finally {
        console.log('deleteInfoRestaurants end')
    }
}

// Suscripciones

export const fetchExchangeSubscriptions = async (context, payload) => {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getExchangesSubscriptionsAdmin/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.warn(error?.response?.data || error) //false cuando es un bad request, el message error error.response.data
        return []
    } finally {
        console.log('fetchExchangeSubscriptions end')
    }
}

export async function fetchExchangeSubscriptionsExcel(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getExchangesSubscriptionsAdmin/`,
            responseType:'blob',
            params: {
                ...payload
            }
        })

        const respuesta = response && response.data
        const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", 'Exchange-subscriptions.xlsx')
        document.body.appendChild(fileLink)
        fileLink.click()
        return true

    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchExchangeSubscriptionsExcel end')
    }
}

// owners general documents

export async function fetchOwnersGeneralDocs(context, payload) {
    try {
        const response = await Vue.axios({
            url: '/fivesClub/getgeneraldocumentsData/',
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error);
        return []
    } finally {
        console.log('fetchOwnersGeneralDocs end')
    }
}

export async function saveOwnersGeneralDocument(context, payload) {
    try {

        const formData = new FormData()
        formData.append('file', payload.file)
        formData.append('data', JSON.stringify(payload))

        const response = await Vue.axios({
            method: 'POST',
            url: '/fivesClub/getgeneraldocumentsData/',
            data: formData
        })
        const respuesta = response && response.data
        const { status } = respuesta
        return status
    } catch (error) {
        console.log(error);
        return false
    } finally {
        console.log('saveOwnersGeneralDocument end')
    }
}

export async function updateOwnersGeneralDocument(context, payload) {
    try {
        const formData = new FormData()
        formData.append('file', payload.file)
        formData.append('data', JSON.stringify(payload))

        const response = await Vue.axios({
            method: 'PUT',
            url: '/fivesClub/getgeneraldocumentsData/',
            data: formData
        })
        const respuesta = response && response.data
        const { status } = respuesta
        return status
    } catch (error) {
        console.log(error);
        return false
    } finally {
        console.log('updateOwnersGeneralDocument end')
    }
}

export async function deleteOwnersGeneralDocument(context, payload) {
    try {

        const response = await Vue.axios({
            method: 'DELETE',
            url: '/fivesClub/getgeneraldocumentsData/',
            data: payload
        })
        const respuesta = response && response.data
        const { status } = respuesta
        return status
    } catch (error) {
        console.log(error);
        return false
    } finally {
        console.log('deleteOwnersGeneralDocument end')
    }
}