export function setLoadingProcess(state, bool) {
    state.isloadingProcess = bool
}

//para altera el estado, setear ordenes
export function setOrders(state, orders) {
    state.orders = orders
}
export function setOrdersItems(state, orders) {
    state.ordersItems = orders
}

export function setUsersChannel(state, users) {
    state.usersChannel = users
}

export function setActiveUsers(state, users) {
    state.activeUsers = []
    state.inactiveUsers = []
    users.forEach(user => {
        if (user.is_active) state.activeUsers.push({ ...user })
        else state.inactiveUsers.push({ ...user })
    });
}

//nuevas mutaciones para refactoring
export function setOrderDetail(state, details) {
    state.orderDetail = details
}

export function setBookingSelected(state, booking) {
    state.bookingSelected = booking
}
export function setBookingSelectedRoom(state, room) {
    state.bookingSelected.rooms = room
}

export function setBookingToEdit(state, value) {
    state.bookingToEdit = value
}

export function setDetailBookingToEdit(state, value) {
    state.detailBookingToEdit = value
}

export function setDataSearchForm(state, data) {
    //Validacion para evitar interrupción  cuando se limpia los tatos del booking 
    if (data==null){
        return false
    }
    //END
    const { adults, hoteldinguscode, language, nights, children,ninos, promotioncode, rangeDate, roomtypecode, vendorcode, idRsv } = data     
    state.searchForm.adults = adults                           
    state.searchForm.dingusCode = hoteldinguscode                      
    state.searchForm.hotel = hoteldinguscode                      
    state.searchForm.language = language
    state.searchForm.nights = nights       
    state.searchForm.numberChildren = children  
    state.searchForm.ninos = ninos  
    state.searchForm.promoCodeManual = promotioncode
    // if (promotioncode.includes('PRICEMATCH')){
    //     state.searchForm.promoCodeManual = ''
    // }
    state.searchForm.rangeDate = rangeDate       
    state.searchForm.roomCode = roomtypecode || ''                				             
    state.searchForm.vendorcode = vendorcode
    state.searchForm.idRsv = idRsv
}
export function setDataSearchForm2(state, data) {     
    state.searchForm = data
}
export function setAgeChildsSearchForm(state, ageChilds) {    
    state.searchForm.ninos = ageChilds
}
export function setNumberChildrenSearchForm(state, number) {    
    state.searchForm.numberChildren = number
}

export function setSpecificAgeChilSearchForm(state, data) {    
    const { key, edad } = data     
    const item = state.searchForm.ninos.find( nino => nino.key === key )
    item.edad = edad
}

export function clearDataSearchForm(state) {        
    state.searchForm.adults = 0                           
    state.searchForm.dingusCode = ''                      
    state.searchForm.hotel = ''                      
    state.searchForm.language = 'en'
    state.searchForm.nights = 0       
    state.searchForm.numberChildren = 0  
    state.searchForm.ninos = []
    state.searchForm.promoCodeManual = ''    
    state.searchForm.rangeDate = ''       
    state.searchForm.roomCode = ''                				             
    state.searchForm.vendorcode = ''
    state.searchForm.idRsv = ''
}

export function setaBookingIsUpdating(state, bool) {    
    state.aBookingIsUpdating = bool                 
}

export function setRoomsBookingSelected(state, rooms) {    
    state.bookingSelected.rooms = rooms               
}

export function setRateSelectedRoom(state, data) {
    const { keyRoom, rate } = data   
    const item = state.bookingSelected.rooms.find(item => item.keyRoom === keyRoom)
    item.detailSelected = rate
    item.priceInit = rate.AmountAfterTax
}

export function setManualPriceSelectedRoom(state, data) {
    const { keyRoom, manualRate } = data   
    const item = state.bookingSelected.rooms.find(item => item.keyRoom === keyRoom)
    item.detailSelected.manualPrice = manualRate
}

export function setRoomsOrderDetail(state, rooms) {
    state.bookingSelected.rooms = rooms
}

export function setRoomsOwner(state, rooms) {
    const roomList={ rooms : [] }  
    roomList.rooms=rooms
    state.bookingSelected = roomList
}
export function setBooking(state, data) {
    const { booking, id } = data
    const item = state.orderDetail.bookings.find(item => item.id === id)
    item.booking = booking
}

export function setRoomBooking(state, data) {
    const { bookingData, id } = data     
    const item = state.orderDetail.bookings.find(item => item.id === id)
    item.booking.room = bookingData
}

export function setDataUserAuthorization(state, data) {
    const { code, idUser, isInvalid, username } = data
    state.userAuthUpdateBooking.isInvalid = isInvalid
    state.userAuthUpdateBooking.idUser = idUser
    state.userAuthUpdateBooking.username = username
    state.userAuthUpdateBooking.code = code
}

export function unsetDataUserAuthorization(state) {
    state.userAuthUpdateBooking.isInvalid = true
    state.userAuthUpdateBooking.idUser = null
    state.userAuthUpdateBooking.username = ''
    state.userAuthUpdateBooking.code = ''
    state.userAuthUpdateBooking.url = ''
}

export function setDetailBeoProduct(state, data) {
    const { idProduct } = data
    const product = state.orderDetail.products.find(item => item.id === idProduct )
    const experience = state.orderDetail.experiences.find(item => item.id === idProduct )
    const item = product || experience
    item.orderdetailbeo.detailbeo.push( data)
}

export function deleteDetailBeoProduct(state, data) {
    const { idProduct, indice } = data
    const product = state.orderDetail.products.find(item => item.id === idProduct )
    const experience = state.orderDetail.experiences.find(item => item.id === idProduct )
    const item = product || experience
    item.orderdetailbeo.detailbeo.splice(indice, 1)
}
//end nuevas mutaciones para refactoring

export function setUserAuthorizationUrl(state, data) {
    state.userAuthUpdateBooking.urlAuthorization = data
}

export function setDetailOrder(state, order) {
    state.detailOrder = order
}

export function setDataTransaction(state, data) {
    state.dataTransaction = data
    state.errorMessageApi = ''
}

export function setDataPaymentLink(state, data) {
    state.dataPaymentLink = data
    state.errorMessageApi = ''
}

export function setTransactionPay(state, data) {
    state.transactionPay = data
    state.errorMessageApi = ''
}

export function setCurrentOrder(state, order) {
    state.selectedOrder = order
}

export function unsetCurrentOrder(state, order) {
    state.selectedOrder = null
}

export function setCurrentTransactions(state, transaction) {
    state.selectedOrder.transactions = transaction
}

export function setBlackoutsDateProduct(state, payload) {
    const { productID, blackoutDates , maxDate  } =  payload
    const idx = state.orderDetail.products.findIndex( item => item.productID === productID ) 
    state.orderDetail.products[idx].blackoutDates.disable = blackoutDates    
    state.orderDetail.products[idx].blackoutDates.maxDate = maxDate   
    state.orderDetail.products[idx].isGettingUnavaliableDates =false
}
export function setCancellationReasons(state, cancellationReasons) {
    state.cancellationReasons = cancellationReasons
}

export function setFiltersFromMySales(state, filters) {
    state.actualfilters = filters
}