import Vue from 'vue'
import { showAlertMessage, consoleMsgFinally } from '@/helpers/helpers'
import { fetchHotels, fetchLanguages } from '@/modules/start/store/actions'


export async function getInitialContentConsuptionsCenters(context, required) {
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }

    if (required?.hotels || true) {
        const hotels = await fetchHotels(payloadHotels)
        context.commit('start/setHotels', hotels || [], { root: true }) //muto state hotels en start
    }
    if (required?.languages || true) {
        const languages = await fetchLanguages()
        context.commit('start/setLanguages', languages || [], { root: true }) //muto state languages
    }
    if (required?.countryCodes || true) {
        const countryCodes = await fetchCountryCodes(context)
        context.commit('setCountryCodes', countryCodes || []) //muto state countryCodes
    }
    return true
}

export async function fetchWaitingList(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/hotelOperation/WaitingList/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : false
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchWaitingList end')
    }
}


export async function fetchWaitingListReport(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/hotelOperation/WaitingList/`,
            responseType:'blob',
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", 'waiting-list-report.xlsx')
        document.body.appendChild(fileLink)
        fileLink.click()
        return true
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchWaitingListReport end')
    }
}

export async function createGuestOnHold(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/hotelOperation/WaitingList/`,
            data:payload
        })

        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        return { status: false, message: '', showMessage: false }
    } finally {
        console.log('createGuestOnHold end')
    }
}

export async function updateGuestOnHold(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/hotelOperation/WaitingList/`,
            data:payload
        })

        const respuesta = response && response.data
        const { status, data } = respuesta
        return status
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('updateGuestOnHold end')
    }
}


export async function releaseGuest(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/hotelOperation/guestOnHold/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : false
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchWaitingList end')
    }
}

export async function fetchWaitingListsCounter(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/hotelOperation/WaitingLists/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchWaitingList end')
    }
}

export async function notifyGuest(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/hotelOperation/notifyGuest/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, message } = respuesta
        return { status, message }
    } catch (error) {
        console.log(error)
        if (error.response.data) {
            const respuesta = error.response && error.response.data
            const { status, message } = respuesta
            return { status, message }
        } else return { status: false, message: 'No se pudo notificar a los clientes, por favor inténtelo más tarde.' }
    } finally {
        console.log('fetchWaitingList end')
    }
}


export async function fetchCountryCodes(context) {
    try {
        const response = await Vue.axios({
            url: `/catalogs/countryCodes/`,
        })
        const respuesta = response && response.data
        return respuesta || []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchWaitingList end')
    }
}

export async function fetchGuestByRoom(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/sihot/getguestbyroom/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        const info = status ?  data : []
        if (info.length == 0){
            showAlertMessage('No se encontró huesped', 'InfoIcon', 'No se ha encontrado información del huésped en Sihot. Favor de añador los datos manualmente', 'warning', 4000)
        }
        return info
    } catch (error) {
        console.log(error)
        if (error.response.data) {
            const respuesta = error.response && error.response.data
            const { message } = respuesta
            console.log(message)
        }

        showAlertMessage('No se encontró huesped', 'InfoIcon', 'No se ha encontrado información del huésped en Sihot. Favor de añador los datos manualmente', 'warning', 4000)
        return []

    } finally {
        console.log('fetchGuestByRoom end')
    }
}

export async function fetchGuestData(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/sihot/getguestdata/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        const info = status ?  data : null
        if (info.length == 0){
            showAlertMessage('No se encontró huesped', 'InfoIcon', 'No se ha encontrado información del huésped en Sihot. Favor de añador los datos manualmente', 'warning', 4000)
        }
        return info
    } catch (error) {
        console.log(error)
        if (error.response.data) {
            const respuesta = error.response && error.response.data
            const { message } = respuesta
            console.log(message)
        }

        showAlertMessage('No se encontró huesped', 'InfoIcon', 'No se ha encontrado información del huésped en Sihot. Favor de añador los datos manualmente', 'warning', 4000)
        return null

    } finally {
        console.log('fetchGuestData end')
    }
}
