
export default [
    {
        path: '/consuptions-centers/waiting-list',
        redirect: { name: 'waiting-list' },
        name: 'guest-pager',
        component: () => import(/*webpackChunkName: 'Housing'*/'@/modules/fivesClub/views/Housing'),
        meta: {
            requiresAuth: true,
            permissions: ["consumption_centers_menu_show_consumption_centers"]
        },
        children: [
            { path: '', name: 'waiting-list', component: () => import(/*webpackChunkName: 'WaitingList'*/'@/modules/guestPager/views/WaitingList'), },
            { path: 'create', name: 'clients-waiting-register', component: () => import(/*webpackChunkName: 'ClientsWaitingCreate'*/'@/modules/guestPager/views/ClientsWaitingCreate'), },
            { path: 'edit/:id', name: 'clients-waiting-edit', component: () => import(/*webpackChunkName: 'ClientsWaitingEdit'*/'@/modules/guestPager/views/ClientsWaitingEdit'), },
        ]
    },
]