export function setLoadingProducts( state, bool) {
    state.isloadingProducts = bool
}
export function setProducts( state, products) {
    state.products = products
}
export function setTransfers( state, transfers) {
    state.transfers = transfers
}

export function setPlaces(state, places) {
    var customOrder = [
        "Aeropuerto Cancun",
        "Aeropuerto Tulum",
        "The Fives Beach",
        "The Fives Downtown",
        "The Fives Oceanfront",
        "Beachfront by The Fives"
    ];

    // Convertir customOrder a minúsculas para comparación insensible a mayúsculas
    var customOrderLower = customOrder.map(name => name.toLowerCase());

    // Dividir los lugares en dos grupos
    var inCustomOrder = [];
    var notInCustomOrder = [];

    places.forEach(place => {
        var placeNameLower = place.displayES ? place.displayES.toLowerCase() : "";
        if (customOrderLower.includes(placeNameLower)) {
            inCustomOrder.push(place);
        } else {
            notInCustomOrder.push(place);
        }
    });

    // Ordenar los lugares en customOrder según el orden personalizado
    var placesInCustomOrder = inCustomOrder.slice().sort(function(a, b) {
        var indexA = customOrderLower.indexOf(a.displayES.toLowerCase());
        var indexB = customOrderLower.indexOf(b.displayES.toLowerCase());
        return indexA - indexB;
    });

    // Ordenar los lugares no en customOrder alfabéticamente
    var placesNotInCustomOrder = notInCustomOrder.slice().sort(function(a, b) {
        var nameA = a.displayES ? a.displayES.toLowerCase() : "";
        var nameB = b.displayES ? b.displayES.toLowerCase() : "";
        return nameA.localeCompare(nameB);
    });

    // Concatenar los lugares ordenados
    state.places = placesInCustomOrder.concat(placesNotInCustomOrder);
}


export function setSortBy( state, option) {
    state.orderBy = option.value
}
export function setSelectedCategory(state, category) {
    state.selectedCategory = category
}

// para ocupar en el buscador, filter
export function setFilter(state, data){
    state.filter[data['filter']] = data.value
}
export function setSelectedExperienceDate(state, date) {
    state.SelectedExperienceDate = date
}

export function setDetailProduct(state, detail) {
    state.productDetail = detail
}
export function unsetDetailProduct(state) {
    state.productDetail = null
}
//un producto al seleccionar su detalle, actualizo su propiedad detailSelected
export function setDetailSelectedInProduct(state, product) {
    let prod = state.products.find( p => p.id === product.id )
    prod.detailSelected = product.detailSelected
}
//propiedad detailSelected en un la vista a detalle de un producto
export function setDetailSelectedProductDetail(state, detail) {    
    state.productDetail.detailSelected = detail
}

export function setQtyProductDetail( state, payload ) {
    
    let sum = 0
    let currencysum = 0
    const { idProduct, idDetail, qty } = payload
    const product = state.products.find( p => p.id === idProduct ) // busco el producto a mutar su detalle
    const idx = product.detail.findIndex( detail => detail.id === idDetail  ) // busco el detalle por el idDetail
    product.detail[idx].qty = qty //muto su cantidad
    product.detail[idx].subTotal = qty * product.detail[idx].saleprice //genero subTotal
    product.detail[idx].displayrates.subTotal = qty * product.detail[idx].displayrates.saleprice //genero subTotal con divisa
    product.detail.forEach(( detalle ) => {
        sum += parseFloat(detalle.subTotal)
        currencysum += parseFloat(detalle.displayrates.subTotal)
    })
    product.total = String((Math.round(sum * 100) / 100).toFixed(2)) //decimal
    product.totaldivisa = String((Math.round(currencysum * 100) / 100).toFixed(2)) //decimal
}

export function setQtySingleProductDetail( state, payload ) { //usado en producto detalle, single product
    
    let sum = 0
    let currencysum = 0
    const { idDetail, qty } = payload
    let product = state.productDetail
    const idx = product.detail.findIndex( detail => detail.id === idDetail  ) // busco el detalle por el idDetail
    product.detail[idx].qty = qty //muto su cantidad
    product.detail[idx].subTotal = qty * product.detail[idx].saleprice //genero subTotal
    product.detail[idx].displayrates.subTotal = qty * product.detail[idx].displayrates.saleprice //genero subTotal con divisa
    product.detail.forEach(( detalle ) => {
        sum += parseFloat(detalle.subTotal)
        currencysum += parseFloat(detalle.displayrates.subTotal)
    })
    product.total = String((Math.round(sum * 100) / 100).toFixed(2)) //decimal
    product.totaldivisa = String((Math.round(currencysum * 100) / 100).toFixed(2)) //decimal
}


export function resetQtyProductDetail( state, payload ) {
    const { idProduct } = payload
    
    const product = state.products.find( p => p.id === idProduct ) // busco el producto a mutar su detalle    
    product.total = '0.00'    
    product.detail.forEach(( detalle ) => {
        detalle.qty = 0         
        detalle.subTotal = 0 
    })
    if(state.productDetail){
        state.productDetail.total = '0.00'    
        state.productDetail.detail.forEach(( detalle ) => {
            detalle.qty = 0         
            detalle.subTotal = 0 
        })
    }

}
