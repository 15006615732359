export function setConsumptionCenters(state, consumptionCenters) {
    state.consumptionCenters = consumptionCenters
}

export function setCurrentConsumptionCenter(state, currentConsumptionCenter) {
    state.currentConsumptionCenter = currentConsumptionCenter
}

export function setCountryCodes(state, countryCodes) {
    state.countryCodes = countryCodes
}
